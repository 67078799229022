import { useState, useCallback } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useStore } from '../../../../hooks/use-store'
import { naturalSort } from '../../../../utils/sort'
import { generateSelectedSpaces } from './time-marker-utils'

export const useTimeMarkers = (timeMarker) => {
  const store = useStore(2)
  const navigate = useNavigate()
  const { setErrorMessage } = useOutletContext()

  let initFields
  let initSpaces = {
    campus: [],
    building: [],
    floor: [],
    zone: [],
  }

  if (timeMarker) {
    initFields = {
      id: timeMarker.id,
      spaceIds: timeMarker.spaceIds,
      title: timeMarker.title,
      description: timeMarker.description,
      startDateTime: timeMarker.startDateTime,
      endDateTime: timeMarker.endDateTime,
    }
    initSpaces = timeMarker.spaceIds.reduce((acc, curr) => {
      const space = store.get(curr)
      acc = generateSelectedSpaces(acc, space)
      return acc
    }, initSpaces)
  } else {
    initFields = {
      id: '',
      spaceIds: [],
      title: '',
      description: '',
      startDateTime: DateTime.now()
        .minus({ days: 1 })
        .toISODate(),
      endDateTime: DateTime.now()
        .toISODate(),
    }
  }

  // TODO: consider refactor to only generate spaceIds array (combining all spaces) in putTimeMarkerFields(), instead of in onChangeSpace()
  // timeMarkerFields would instead contain separate campus/building/floor/zone arrays
  const [timeMarkerFields, setTimeMarkerFields] = useState(initFields)
  const [selectedSpaces, setSelectedSpaces] = useState(initSpaces)

  const updateTimeMarkerFields = (field, value) => {
    setTimeMarkerFields((fields) => {
      return {
        ...fields,
        [field]: value,
      }
    })
  }

  const resetTimeMarkerFields = () => {
    setTimeMarkerFields({
      id: '',
      spaceIds: [],
      title: '',
      description: '',
      startDateTime: '',
      endDateTime: '',
    })
  }

  const setFromTo = useCallback(
    (range) => {
      if (!range?.from || !range?.to) {
        return
      }
      const from = DateTime.fromJSDate(range.from)
        .toISODate()
      const to = DateTime.fromJSDate(range.to)
        .plus({ days: 1 })
        .toISODate()
      if (from !== timeMarkerFields.startDateTime) {
        updateTimeMarkerFields('startDateTime', from)
      }
      if (to !== timeMarkerFields.endDateTime) {
        updateTimeMarkerFields('endDateTime', to)
      }
    },
    [timeMarkerFields.startDateTime, timeMarkerFields.endDateTime]
  )

  const resetSelectedSpaces = () => {
    setSelectedSpaces({
      campus: [],
      building: [],
      floor: [],
      zone: [],
    })
  }

  const onChangeSpace = (value) => {
    const space = store.get(value)
    const spaceType = space.type
    const spaceIds = selectedSpaces[spaceType]

    const updatedSpaces = { ...selectedSpaces }

    // Use new id, or remove if already exists
    if (!spaceIds.includes(value)) {
      updatedSpaces[spaceType] = [value]
    } else {
      updatedSpaces[spaceType] = selectedSpaces[spaceType].filter(
        (id) => id !== value
      )
    }

    // After any change, clear child fields
    if (spaceType === 'campus') {
      updatedSpaces.building = []
      updatedSpaces.floor = []
      updatedSpaces.zone = []
    } else if (spaceType === 'building') {
      updatedSpaces.floor = []
      updatedSpaces.zone = []
    } else if (spaceType === 'floor') {
      updatedSpaces.zone = []
    }

    setSelectedSpaces(updatedSpaces)

    // TODO: handle multiple spaces, add logic to work out which ids to pick out
    let newSpaceIds = []
    if (updatedSpaces.zone.length) {
      newSpaceIds.push(updatedSpaces.zone[0])
    } else if (updatedSpaces.floor.length) {
      newSpaceIds.push(updatedSpaces.floor[0])
    } else if (updatedSpaces.building.length) {
      newSpaceIds.push(updatedSpaces.building[0])
    } else if (updatedSpaces.campus.length) {
      newSpaceIds.push(updatedSpaces.campus[0])
    }
    updateTimeMarkerFields('spaceIds', newSpaceIds)
  }

  const closePopover = () => {
    resetSelectedSpaces()
    resetTimeMarkerFields()
    setErrorMessage(null)
    navigate('/tools/timemarkers')
  }

  const campuses = store.campuses()

  const campusItems = campuses.map((campus) => {
    return {
      key: campus.id,
      title: campus.name,
    }
  })

  // TODO: handle multiple selected buildings/floors/zones

  const buildingItems = selectedSpaces.campus.length
    ? store
        .get(selectedSpaces.campus[0])
        .buildings.map((building) => {
          return {
            key: building.id,
            title: building.name,
          }
        })
        .sort((a, b) => naturalSort(a.title, b.title))
    : []

  const floorItems = selectedSpaces.building.length
    ? store
        .floors(selectedSpaces.building[0])
        .map((floor) => {
          return {
            key: floor.id,
            title: floor.name,
          }
        })
        .sort((a, b) => naturalSort(a.title, b.title))
    : []

  const zoneItems = selectedSpaces.floor.length
    ? store
        .zones(selectedSpaces.floor[0])
        .map((zone) => {
          return {
            key: zone.id,
            title: zone.name,
          }
        })
        .sort((a, b) => naturalSort(a.title, b.title))
    : []

  return {
    timeMarkerFields,
    updateTimeMarkerFields,
    setFromTo,
    closePopover,
    selectedSpaces,
    onChangeSpace,
    campusItems,
    buildingItems,
    floorItems,
    zoneItems,
  }
}
