import { createDatetime, createInterval } from './date-utils'
import { isGroupTag, getZoneTagKey } from './tag-utils'
import { descending, group, sum } from 'd3-array'
import { polygonArea } from 'd3-polygon'
import { intersection } from 'polygon-clipping'
import memo from 'memoize-one'

import { UNIFIED_DATA_SOURCE_NAME } from '../constants'
import { PRODUCTION } from '../utils/debug'

/**
 * Pass any space-like object and get an array with zones
 **/
export function getZones(space, store, dates = {}) {
  console.warn('@TODO: replace getZones with useActiveZones() from use-active-zones')
  let result

  function isZone(space) {
    return space?.type === 'zone'
  }

  switch (true) {
    case !space || typeof space !== 'object':
      result = []
      break
    case Array.isArray(space):
      result = space.flatMap((s) => getZones(s, store, dates))
      break
    case isZone(space):
      result = [space]
      break
    case Boolean(space.zones):
      if (store && dates.start && dates.end) {
        let interval = createInterval(dates.start, dates.end)
        result = space.zones
          .map((zone) => {
            let allZonePeriods = store.findIdAll(zone.id)
            let matches = allZonePeriods.filter((period) =>
              period.interval.overlaps(interval)
            )
            return matches[matches.length - 1]
          })
          .filter(Boolean)
      } else {
        // console.log('no store & no dates', space.zones, store, dates)
        result = space.zones
      }
      break
    case Boolean(space.buildings):
      result = getZones(space.buildings, store, dates)
      break
    default:
      throw new Error('unknown space')
  }

  return result
}

export function getFilterZones(space, filters = {}, store) {
  console.warn('@TODO: replace getFilterZones with useActiveZonesByFilter() from use-active-zones')
  let {
    dates = {},
    tags = [],
    tagOptions = { join: 'every', match: 'exact' },
    datasource,
    metadata = [],
  } = filters

  function hasMetadata(metadata) {
    return function zoneHasMetadata(zone) {
      if (!Array.isArray(metadata)) {
        metadata = [metadata]
      }
      return metadata.every((key) => {
        if (key === 'has_capacity') {
          return zone.capacity.get('default') > 0
        } else if (key in zone.metadata) {
          return zone.metadata[key]
        } else {
          return false
        }
      })
    }
  }

  function hasTags(input, options = { join: 'every', match: 'exact' }) {
    if (!Array.isArray(input)) {
      input = [input]
    }
    let tags = input.filter(isGroupTag)
    let someOrEvery = options?.join ?? 'every'
    let checkIfZoneHasTag = {
      exact: (tag) => (zone) => zone?.tags.includes(tag),
      key: (tag) => (zone) => zone?.tags.some((tag) => tag.startsWith(tag)),
      value: (tag) => (zone) => zone?.tags.some((tag) => tag.endsWith(tag)),
    }[options?.match ?? 'exact']

    return function zoneHasTags(zone) {
      return tags[someOrEvery](checkIfZoneHasTag)
    }
  }

  function hasOverlappingDates(dirtyStart, dirtyEnd) {
    let start = createDatetime(dirtyStart)
    let end = createDatetime(dirtyEnd)

    return (zone) => {
      let zoneStart = createDatetime(zone.startdate)
      let zoneEnd = createDatetime(zone.enddate)
      return zoneStart <= end && zoneEnd >= start
    }
  }

  let zones = getZones(space, store, dates)

  if (datasource) {
    zones = zones.filter((zone) => hasDataSource(zone, datasource))
  }

  if (dates?.start && dates?.end) {
    zones = zones.filter(hasOverlappingDates(dates.start, dates.end))
  }

  if (tags.length > 0) {
    // change this to `some` to combine 'OR' together instead of 'AND'
    zones = zones.filter(hasTags(tags, tagOptions))
  }

  if (metadata.length > 0) {
    zones = zones.filter(hasMetadata(metadata))
  }

  return zones
}

export function computeLargestLayer (zones) {
  let layers = group(zones, z => z.layer_name)
  let layers_by_size = Array.from(
    layers.entries(),
    ([ layer_name, zones ]) => [layer_name, sum(zones.map(zone => zone.m2))]
  )
  let largest_layer = layers_by_size.sort((a, b) => descending(a[1], b[1]))[0][0]
  return largest_layer
}

export function totalCapacity (zones) {
  let capacityTotals = new Map()
  if (!Array.isArray(zones)) {
    return capacityTotals
  }

  console.assert(zones.every(z => z.type === 'zone'), { zones })

  let layers = new Set(zones.map(z => z.layer_name))
  if (layers.size > 1) {
    if (hasUnifiedZones(zones)) {
      // console.log('Computing capacity by filtering zones with floor_level_aggregate')
      zones = zones.filter(zone => hasFloorAggregate(zone))
    } else {
      let layer = computeLargestLayer(zones)
      zones = zones.filter(zone => zone.layer_name === layer)
      console.debug('No Unified data handling but multiple layers. Filtering on largest layer... ', {zones, layers, layer })
    }
  }

  for (let zone of zones) {
    for (let [capacityName, capacityValue] of zone.capacity) {
      let total = capacityTotals.get(capacityName) ?? 0
      capacityTotals.set(capacityName, capacityValue + total)
    }
  }

  return capacityTotals
}

export function uniqCapacities (zones) {
  let capacityNames = new Set()
  for (let zone of zones) {
    if (zone.capacity.size > 0) {
      for (let [capacityName] of zone.capacity) {
        capacityNames.add(capacityName)
      }
    }
  }

  return capacityNames
}

export function uniqTagGroups (zones) {
  let layers = new Set(zones.map(z => z.layer_name))
  if (layers.size > 1) {
    if (hasUnifiedZones(zones)) {
      zones = zones.filter(zone => hasFloorAggregate(zone))
    }
  }

  let output = new Set()
  for (let zone of zones) {
    for (let tag of zone.tags) {
      output.add(getZoneTagKey(tag))
    }
  }
  return output
}

export function hasDataSource (zone, datasource, datasourceTypes) {
  let hasDataSource = datasource === UNIFIED_DATA_SOURCE_NAME
    ? isUnifiedZone(zone)
    : datasource ? zone.layer_name === datasource : true

  let hasDataSourceType = (Array.isArray(datasourceTypes) && datasourceTypes.length > 0)
    ? datasourceTypes.includes(zone.datasource.datasource_type)
    : true

  return hasDataSource && hasDataSourceType
}

export function hasFloorAggregate(zone) {
  return zone.metadata.floor_level_aggregate
}

export function hasFloorAggregateSubtract(zone) {
  return zone.metadata.floor_level_aggregate_subtract
}

export function hasUnifiedZones (zones) {
  return zones.some(isUnifiedZone)
}

export function isBookable (zone) {
  return zone.metadata.has_bookingsource
}

export function isCollaboration (zone) {
  return !isDesk(zone) && !isMeetingRoom(zone)
}

export function isDesk (zone) {
  return zone.metadata.desk || isDeskLike(zone)
}

export function isDeskLike (zone) {
  return (
    zone.capacity.get('default') === 1
    && zone.m2 < 1.5
    && zone.layer_name === 'sensorlayer'
  )
}

export function isMeetingRoom (zone) {
  return zone.metadata.meeting_room
}

export function getSpaceType (space) {
  if (isDesk(space)) return 'desk'
  if (isMeetingRoom(space)) return 'meeting'
  if (isCollaboration(space)) return 'collaboration'
  return 'other'
}

export function isUnifiedZone (zone) {
  return hasFloorAggregate(zone) || hasFloorAggregateSubtract(zone)
}

export function createZoneFilters (capacity, datasource, datasourceTypes, metadata, tags, spaceLayer) {
  let yes = () => true
  let filters = {
    capacity: (zone) => zone.capacity.has(capacity),
    datasource: (datasource === UNIFIED_DATA_SOURCE_NAME)
      ? isUnifiedZone
      : datasource
      ? (zone) => zone.layer_name === datasource
      : yes,
    datasourceTypes: (Array.isArray(datasourceTypes) && datasourceTypes.length > 0)
      ? (zone) => datasourceTypes.includes(zone.datasource.datasource_type)
      : yes,
    metadata: (Array.isArray(metadata) && metadata.length > 0)
      ? (zone) => metadata.every(key => zone.metadata?.[key] ?? false)
      : yes,
    tags: (Array.isArray(tags) && tags.length > 0)
      ? (zone) => tags.some(tag => zone.tags.includes(tag))
      : yes,
    spaceLayer: !!spaceLayer
      ? (zone) => zone.metadata?.space_type === spaceLayer
      : yes
  }

  let chain = Object.values(filters)
  filters.every = (zone) => chain.every(F => F(zone))

  return filters
}

export function getContainedZones (zones) {
  console.time('getContainedZones')
  let output = []

  // group by floor
  for (let [/*_*/, floorZones] of group(zones, z => z.floor_id)) {
    // console.log(floorZones[0].building_name, floorZones[0].floor_name)

    // identify layers
    let layers = group(floorZones, z => z.layer_name)

    // @TODO: generalize to handle more than just sensor+wifi layer
    // e.g. wifibookinglayer + sensorlayer + wifilayer
    if (layers.size !== 2 || !(layers.has('sensorlayer') && layers.has('wifilayer'))) {
      // console.log('-- no sensor+wifi layers')
      output.push(...floorZones)
      continue
    }

    let sensorZones = layers.get('sensorlayer')
    let wifiZones = layers.get('wifilayer')
    // a place to store all children we've processed
    let children = []

    // loop over sensor zones to identify their potential container zones
    for (let sensorZone of sensorZones) {
      if (sensorZone.parent) {
        output.push(sensorZone)
        continue
      }

      // console.group(sensorZone.name)
      let sensorPolygon = [sensorZone.points]

      // compute intersection with all Wifi zones
      let possibleParents = wifiZones.map(wifiZone => {
        let match = intersection(sensorPolygon, [wifiZone.points])
        let area = match.length > 0 ? Math.abs(polygonArea(match[0][0])) : null
        return {
          wifiZone,
          intersection: match,
          area
        }
      })

      let parents = possibleParents.filter(match => match.area != null)

      // stand-alone zone - let's move on
      if (parents.length === 0) {
        // console.log('-- sensor zone with no parents', {sensorZone, parents, possibleParents})
        // console.assert(hasFloorAggregate(sensorZone), { msg: 'missing "floor_level_aggregate" on Sensor zone without containing Wifi zone', sensorZone })
        output.push(sensorZone)
        continue
      }

      // prefer the candidate with the largest intersecting area
      if (parents.length > 1) {
        parents.sort((a, b) => descending(a.area, b.area))
        // console.log('-- sensor zone with multiple parents', { sensorZone, parents })
      }

      let parent = parents[0].wifiZone

      // create a copy
      console.assert(sensorZone.parent == null, {sensorZone, parents})
      let child = {
        ...sensorZone,
        parent
      }

      children.push(child)
      // console.log(sensorZone.name, 'added', {child, parent})
    }

    for (let wifiZone of wifiZones) {
      if (wifiZone.children) {
        if (!PRODUCTION) {
          let wifiCapacity = wifiZone.capacity.get('default')
          let childrenCapacity = wifiZone.children.reduce((sum, zone) => sum + zone.capacity.get('default'), 0)

          // capacity
          if (wifiCapacity < childrenCapacity) {
            console.debug(
              `Wifi capacity lower than contained zones`,
              { name: wifiZone.name, wifiCapacity, childrenCapacity, wifiZone }
            )
          }

          // check floor_level_aggregate & floor_level_aggregate_subtract
          if (!hasFloorAggregate(wifiZone)) {
            console.debug(
              'Wifi zone has contained zones but does not have missing floor_level_aggregate',
              { wifiZone }
            )
          }

          if (wifiZone.children.some(c => !hasFloorAggregateSubtract(c))) {
            console.debug(
              'Sensor zones contained in Wifi zone but not each one has floor_level_aggregate_subtract',
              { wifiZone, childrenMissing: wifiZone.children.filter(c => !hasFloorAggregateSubtract(c)) }
            )
          }
        }

        output.push(wifiZone)
        continue
      }

      let wifiChildren = children.filter(z => z.parent === wifiZone)
      output.push({
        ...wifiZone,
        children: wifiChildren
      })
    }

    output.push(...children)
  }

  console.assert(output.length === zones.length, { output, zones })
  console.timeEnd('getContainedZones')

  return output
}

export let getContainedZonesMemo = memo(getContainedZones)
