import { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { AppLoader } from '../../components/app-loader'
import EditTimeMarker from './pages/time-markers/edit-time-marker'
import CreateTimeMarker from './pages/time-markers/create-time-marker'
import features from '../../utils/features'

const BookingMetricsExportPage = lazy(() => import('./pages/booking-metrics-export-page'))
const ClocksPage = lazy(() => import('./pages/clocks-page'))
const ConsolidateFloorsPage = lazy(() => import('./pages/consolidate-floors-page'))
const CorporateNoShowsPage = lazy(() => import('./pages/corporate-noshows-page'))
const ExportPage = lazy(() => import('./pages/export-page'))
const FindVacantSeatPage = lazy(() => import('./pages/find-vacant-seat-page'))
const NoShowsPage = lazy(() => import('./pages/noshows-page'))
const NotFoundPage = lazy(() => import('../not-found/not-found-page'))
const UploadPage = lazy(() => import('./pages/upload-page'))
const ZoneMetricsExportPage = lazy(() => import('./pages/zone-metrics-export-page'))
const TimeMarkersPage = lazy(() => import('./pages/time-markers/time-markers-page'))
const ZonesPage = lazy(() => import('../test/zones-page'))
const UptimePage = lazy(() => import('./uptime/uptime-page'))

export function ToolsRoutes () {
  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path='clocks' element={<ClocksPage />} />
        <Route path='noshows' element={<NoShowsPage />} />
        <Route path='corporate-noshows' element={<CorporateNoShowsPage />} />
        <Route path='upload' element={<UploadPage />} />
        <Route path='uptime' element={<UptimePage />} />
        <Route path='find-team-space' element={<FindVacantSeatPage />} />
        <Route path='export' element={<ExportPage />}>
          <Route path='zone-metrics' element={<ZoneMetricsExportPage />} />
          <Route path='booking-metrics' element={<BookingMetricsExportPage />} />
        </Route>
        <Route path='consolidate/*' element={<ConsolidateFloorsPage />} />
        <Route path='timemarkers' element={<TimeMarkersPage />}>
          <Route path='create' element={<CreateTimeMarker />} />
          <Route path=':id/edit' element={<EditTimeMarker />} />
        </Route>
        <Route path='zones' element={<ZonesPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}
