import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Flex } from '@lonerooftop/kitt-ui'
import { Button, ButtonPills } from '../button'
import { DropdownMenu } from '../dropdown'
import { FaCheck as Checked } from 'react-icons/fa'
import { Label } from '../text'

import { useSetDataSource, useSetDataSourceType } from '../../hooks/use-page-settings'
import { useActiveDataSource } from '../../hooks/use-active-data-source'
import { formatDataSource, formatString } from '../../utils/format'
import { addOrRemove } from '../../utils/select-utils'

export function SelectQueryDataSource () {
  let { activeDataSource, activeDataSourceTypes, availableLayers } = useActiveDataSource()
  let setDataSource = useSetDataSource()
  let setDataSourceType = useSetDataSourceType()

  return (
    <SelectDataSource
      datasource={activeDataSource}
      layers={availableLayers}
      datasourceType={activeDataSourceTypes}
      setDataSource={setDataSource}
      setDataSourceType={setDataSourceType}
    />
  )
}

export function SelectDataSource (props) {
  let { datasource, datasourceType, layers, setDataSource, setDataSourceType } = props

  /**
   * This should handle any combination of data layers(s) and data types
   * - Wifi-only, sensor-only
   * - Wifi + sensor
   * - Wifi + sensor + unified
   * - Sensor-only but multiple types
   * - Wifi + sensor with multiple types
   * - Wifi + sensor with multiple types + unified
   **/

  if (layers.size === 0) {
    return null
  }

  console.assert(layers.has(datasource), { layers, datasource })
  console.assert(layers.get(datasource) instanceof Set)

  // if (layers.has(UNIFIED_DATA_SOURCE_NAME)) {
  //   let layer = UNIFIED_DATA_SOURCE_NAME
  //   let types = layers.get(UNIFIED_DATA_SOURCE_NAME)

  //   return (
  //     <Container>
  //       <ButtonPills>
  //         <Button
  //           icon={datasource === layer && <Checked size='.875rem' />}
  //           onClick={() => setDataSource(layer)}
  //         >
  //           {formatDataSource(layer)}
  //         </Button>
  //         <DropdownMenu
  //           items={Array.from(types).map(type => ({
  //             key: type,
  //             title: formatString(type),
  //             selectedTitle: formatString(type)
  //           }))}
  //           selected={datasourceType}
  //           onChange={setDataSourceType}
  //           onClear={() => setDataSourceType(null)}
  //           forceTitle
  //           title=''
  //           multiple
  //         />
  //       </ButtonPills>
  //     </Container>
  //   )
  // }

  if (layers.size === 1 && layers.get(datasource).size <= 1) {
    return (
      <Container>
        {formatDataSource(datasource)}
      </Container>
    )
  } else {
    // @TODO: considering that we're adding more data sources,
    // we should render these in a dropdown. We'll need to refactor the query setters
    // for this as well.
    return (
      <Container>
        <ButtonPills>
        {Array.from(layers, ([layer, types]) => {
          // only display dropdown with types if there are multiple types and if the datasource is active
          if (types.size > 1 && datasource === layer) {
            return (
              <Fragment key={layer}>
                <Button
                  active={datasource === layer}
                  icon={datasource === layer && <Checked size='.875rem' />}
                  onClick={() => setDataSource(layer)}
                >
                  {formatDataSource(layer)}
                </Button>
                <DropdownMenu
                  items={Array.from(types).map(type => ({
                    key: type,
                    title: formatString(type),
                    selectedTitle: formatString(type)
                  }))}
                  selected={datasourceType}
                  onChange={next => setDataSourceType(addOrRemove(next, datasourceType))}
                  onClear={() => setDataSourceType([])}
                  forceTitle
                  title={datasourceType.length > 0 ? `(${datasourceType.length})` : ''}
                  multiple
                />
              </Fragment>
            )
          }
          return (
            <Button
              key={layer}
              active={datasource === layer}
              icon={datasource === layer && <Checked size='.875rem' />}
              onClick={() => setDataSource(layer)}
            >
              {formatDataSource(layer)}
            </Button>

          )
        })}
        </ButtonPills>
      </Container>
    )
  }
}

SelectDataSource.propTypes = {
  layers: PropTypes.instanceOf(Map),
  setDataSource: PropTypes.func.isRequired,
  setDataSourceType: PropTypes.func
}

function Container ({ children }) {
  return (
    <Flex alignItems='center' className='select-data-source' data-test-id='select-data-source'>
      <Label mb={0} mr={3} truncate>
        Data source
      </Label>
      {children}
    </Flex>
  )
}
