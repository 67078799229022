import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { Text } from '@lonerooftop/kitt-ui'
import { DropdownMenu } from '../dropdown'

import { useStartDate, useEndDate, useSetDates } from '../../hooks/use-page-settings'
import { useStore, useSpaceHierarchy } from '../../hooks/use-store'

import { createInterval, createFirstOfMonth, createToday } from '../../utils/date-utils'
import { timeframes } from '../../utils/timeframes'

import { LOCALE } from '../../constants'

export function SelectQueryTimeFrameToday () {
  return (
    <Text fontSize={4}>
      Today
    </Text>
  )
}

export function SelectQueryTimeFrameDropdown ({ timeframe }) {
  let setDates = useSetDates()
  let timeframes = useCreateTimeframe(timeframe)
  let { ids} = useSpaceHierarchy()
  let startdatetime = useStartDate()
  let enddatetime = useEndDate()
  let store = useStore(2)
  let [ minDate, maxDate ] = store.getMinMaxDate(ids)

  let items = timeframes.map((item, index) => {
    if (!item) return null
    return {
      key: `${index}`,
      title: item.title,
      tooltipTitle: `${formatDate(item.start)}–${formatDate(item.end)}`,
      // exclude timeframes before and after the available dates, but still allow overlap
      disabled: (item.start !== undefined || item.end !== undefined) ? item.start < minDate || item.end > maxDate : true
    }
  })

  let onChange = useCallback(
    function onChange (key) {
      try {
        let { start, end } = timeframes[parseInt(key, 10)]
        setDates(start.toISODate(), end.toISODate())
      } catch (error) {
        throw new Error('Invalid timeframe key ' + key)
      }
    }
  , [ timeframes, setDates ])

  let selected = timeframes.findIndex(timeframe =>
    timeframe?.start?.toISODate() === startdatetime
    && timeframe?.end?.toISODate() === enddatetime
  )

  return (
    <DropdownMenu
      className='select-time-frame'
      items={items}
      selected={`${selected}`}
      onChange={onChange}
      title='Custom'
      data-test-id='select-time'
    />
  )
}

SelectQueryTimeFrameDropdown.propTypes = {
  timeframe: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]).isRequired
}


function useCreateTimeframe (config) {
  let store = useStore(2)
  let [minDate, maxDate] = store.getMinMaxDate()
  let numberOfMonths = createInterval(minDate, maxDate).count('month') - 1
  let months = []

  // return months, sorted new -> old
  for (let month = 0; month < numberOfMonths; month++) {
    let start = createFirstOfMonth().minus({ month })
    let end = start.plus({ month: 1 })
    let interval = createInterval(start, end)
    months.push({
      title: start.toLocaleString({ month: 'long', year: 'numeric' }),
      start,
      end,
      interval
    })
  }

  let today = createToday()
  let working_days = Math.floor(today.daysInMonth / 7) * 5
  let min_day = Math.round(working_days * 0.9)

  // auto-select the current month when 90% of its working days have past, otherwise, select the previous one
  months.initial = months[today.day > min_day ? 0 : 1] || months[0] // fall-back when there's only 1 available months

  /** config can be:

  - a key from timeframes, e.g. 'today'
  - a hardcoded option value, e.g. 'months'
  - an array of the above
  **/
  if (Array.isArray(config)) {
    return config.flatMap(key => key === 'months' ? months : timeframes?.[key] ?? null)
  } else {
    return [config === 'months' ? months : timeframes[config]]
  }
}

const formatDate = new Intl.DateTimeFormat(LOCALE, DateTime.DATE_FULL).format
