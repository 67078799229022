import { Text, Flex, Box, Spinner } from '@lonerooftop/kitt-ui'
import { PrimaryButton } from '../../../../components/button'
import { DropdownMenu } from '../../../../components/dropdown'
import { SelectDateRangePicker } from '../../../../components/select/select-date-range'
import { Required } from '../../../../components/form'
import {
  MoreSettings,
  MoreSettingsItem,
  MoreSettingsItemLabel,
  MoreSettingsItemControl,
} from '../../../../components/app/header'
import { createDatetime, createToday } from '../../../../utils/date-utils'
import { useStore } from '../../../../hooks/use-store'

export const TimeMarkerFields = (props) => {
  const {
    mainCTA,
    mainCTALabel,
    timeMarkerFields,
    updateTimeMarkerFields,
    setFromTo,
    selectedSpaces,
    onChangeSpace,
    campusItems,
    buildingItems,
    floorItems,
    zoneItems,
    loading,
    hasFieldsChanged = true
  } = props

  const store = useStore(2)

  // get min and max dates for date range based on building selection
  let buildingIds = buildingItems.map((b) => b.key)
  let yesterday = createToday().minus({ days: 1 }).toJSDate()
  let [minDate, _maxDate] = store.getMinMaxDate(buildingIds)
  let maxDate = _maxDate < yesterday ? _maxDate : yesterday

  return (
    <>
      <Box className="more-settings">
        <MoreSettings>
          <MoreSettingsItem>
            <MoreSettingsItemLabel>
              <Flex>
                <Required />
                <Text>Campus</Text>
              </Flex>
            </MoreSettingsItemLabel>
            <MoreSettingsItemControl>
              <DropdownMenu
                title="Choose"
                items={campusItems}
                /* multiple */
                selected={selectedSpaces?.campus}
                onChange={onChangeSpace}
                fullWidth
              />
            </MoreSettingsItemControl>
          </MoreSettingsItem>

          <MoreSettingsItem>
            <MoreSettingsItemLabel>Building</MoreSettingsItemLabel>
            <MoreSettingsItemControl>
              <DropdownMenu
                title="Choose"
                items={buildingItems}
                /* multiple */
                selected={selectedSpaces?.building}
                onChange={onChangeSpace}
                fullWidth
              />
            </MoreSettingsItemControl>
          </MoreSettingsItem>

          <MoreSettingsItem>
            <MoreSettingsItemLabel>
              <Text>Floor</Text>
            </MoreSettingsItemLabel>
            <MoreSettingsItemControl>
              <DropdownMenu
                title="Choose"
                items={floorItems}
                /* multiple */
                selected={selectedSpaces?.floor}
                onChange={onChangeSpace}
                fullWidth
              />
            </MoreSettingsItemControl>
          </MoreSettingsItem>

          <MoreSettingsItem>
            <MoreSettingsItemLabel>
              <Text>Zone</Text>
            </MoreSettingsItemLabel>
            <MoreSettingsItemControl>
              <DropdownMenu
                title="Choose"
                items={zoneItems}
                /* multiple */
                selected={selectedSpaces?.zone}
                onChange={onChangeSpace}
                fullWidth
              />
            </MoreSettingsItemControl>
          </MoreSettingsItem>

          <MoreSettingsItem>
            <MoreSettingsItemLabel>
              <Flex>
                <Required />
                <Text>Date range</Text>
              </Flex>
            </MoreSettingsItemLabel>
            <MoreSettingsItemControl>
              <SelectDateRangePicker
                fromDate={createDatetime(
                  timeMarkerFields?.startDateTime
                ).toJSDate()}
                toDate={createDatetime(timeMarkerFields?.endDateTime)
                  .minus({ days: 1 })
                  .toJSDate()}
                setFromTo={setFromTo}
                buttonClass="width-100"
                minDate={minDate}
                maxDate={maxDate}
              />
            </MoreSettingsItemControl>
          </MoreSettingsItem>

          <MoreSettingsItem>
            <MoreSettingsItemLabel>
              <Flex>
                <Required />
                <Text>Title</Text>
              </Flex>
            </MoreSettingsItemLabel>
            <MoreSettingsItemControl>
              <input
                type="text"
                value={timeMarkerFields?.title}
                onChange={(e) =>
                  updateTimeMarkerFields('title', e.target.value)
                }
              />
            </MoreSettingsItemControl>
          </MoreSettingsItem>

          <MoreSettingsItem>
            <MoreSettingsItemLabel>
              <Text>Description</Text>
            </MoreSettingsItemLabel>
            <MoreSettingsItemControl>
              <input
                type="text"
                value={timeMarkerFields?.description}
                onChange={(e) =>
                  updateTimeMarkerFields('description', e.target.value)
                }
              />
            </MoreSettingsItemControl>
          </MoreSettingsItem>
        </MoreSettings>
      </Box>

      <PrimaryButton
        onClick={mainCTA}
        disabled={loading || !hasFieldsChanged}
        py={loading ? 2 : 3}
        className="width-100"
      >
        {loading ? <Spinner size="small" color="white" /> : mainCTALabel}
      </PrimaryButton>
    </>
  )
}
