import { difference } from 'polygon-clipping'
import { polygonCentroid, polygonContains } from 'd3-polygon'
import { DateTime, Interval } from 'luxon'
import { formatCampusName } from './format'
import { createDatetime } from './date-utils'

const tomorrow = createDatetime().startOf('day').plus({ days: 1 })

function processTags (tags) {
  return tags
    .filter(tag =>
      !tag.startsWith('custom')
      && !tag.startsWith('hide')
      && !tag.startsWith('UID')
    )
    .map(tag => tag.replace(':', '.').replace('_', '.'))
}

function processZoneLayers (spaces) {
  let layers = new Set(spaces.map(z => z.layer_name ))
  return Array.from(layers).map(name => ({ name }))
}

export class Infrastructure {
  constructor (buildings, zones, building_settings = []) {
    console.time('create Infrastructure')
    this.zone_periods_by_id = {}
    this.floor_periods_by_id = {}
    this.building_periods_by_id = {}

    zones.forEach(zone => {
      const { id, floor_id, building_id, zone_periods } = zone
      const { timezone } = buildings.find(b => b.id === building_id)

      this.zone_periods_by_id[id] = zone_periods.map(period => {
        const interval = makeInterval(period)

        return {
          id,
          floor_id,
          building_id,
          ...period,
          tags: processTags(period.tags),
          // startdate: DateTime.fromISO(period.startdate),
          // enddate: period.enddate ? DateTime.fromISO(period.enddate) : tomorrow,
          filter_tag: 'id',
          interval,
          type: 'zone',
          capacity: new Map(period.capacity),
          centroid: polygonCentroid(period.normalised_points),
          timezone
        }
      })
    })

    this.zone_periods = Object.values(this.zone_periods_by_id)

    buildings.forEach(building => {
      const { id, timezone, building_periods } = building
      const settings = building_settings.find(s => s.building === id) || {}

      if (Array.isArray(settings.excluded_periods) && settings.excluded_periods.length > 0) {
        settings.excluded_periods.forEach(period => {
          period.startdatetime = DateTime.fromISO(period.startdate)
          period.enddatetime = period.enddate === null ? tomorrow : DateTime.fromISO(period.enddate)
          period.interval = makeInterval(period)
        })
      }

      const periods = building_periods.map(building_period => {
        const interval = makeInterval(building_period)
        const zones = latestOverlappingPeriod({
          periods: this.zone_periods.filter(periods => periods.every(period => period.building_id === id)),
          interval
        })

        if (zones.length === 0) {
          console.error({
            zones,
            building_zones: this.zone_periods.filter(periods => periods.every(period => period.building_id === id)),
            building_period,
            msg: 'No zones found that match this period'
          })
          return null
        }

        const floors = building_period.floors.map(floor => {
          const { id, floor_periods: [ period ] } = floor

          if (floor.floor_periods.length > 1) {
            throw new Error(`Building ${period.name} period ${period.startdate}-${period.enddate} has multiple floor periods.`)
          }

          // const interval = makeInterval(period)
          const floor_zones = latestOverlappingPeriod({
            periods: this.zone_periods.filter(periods => periods.every(period => period.floor_id === id)),
            interval
          })
          const zone_layers = processZoneLayers(floor_zones)
          const shortname = parseInt(period.name.replace('Floor ', ''), 10)

          return {
            id,
            ...period,
            startdate: building_period.startdate,
            enddate: building_period.enddate,
            filter_tag: 'floor_id',
            interval,
            type: 'floor',
            // startdate: DateTime.fromISO(floor_period.startdate),
            // enddate: floor_period.enddate ? DateTime.fromISO(floor_period.enddate) : tomorrow,
            name: Number.isFinite(shortname) ? `Floor ${shortname}` : period.name,
            shortname: Number.isFinite(shortname) ? shortname : period.name,
            timezone,
            building_id: building.id,
            zones: floor_zones,
            zone_layers
          }
        }).filter(floor => floor.zones.length > 0)

        // dirty - our map now has a side effect
        floors.forEach(floor => {
          // if floors every have more than 1 period, this logic should be adjusted
          if (!this.floor_periods_by_id[floor.id]) {
            this.floor_periods_by_id[floor.id] = [floor]
          } else {
            this.floor_periods_by_id[floor.id].push(floor)
          }
        })

        const zone_layers = processZoneLayers(zones)

        if (settings.default_layer && !zone_layers.some(({ name }) => name === settings.default_layer)) {
          console.warn(`${building_period.name} has invalid default_layer "${settings.default_layer}", available: ${zone_layers.map(l => l.name).join(', ')}`)
          settings.default_layer = zone_layers[0]?.name
        }

        const campus_id = building_period.tags.find(t => t.includes('campus:'))
        const campus_name = campus_id.replace('campus:', '')

        return {
          id,
          timezone,
          ...building_period,
          floors,
          zones,
          // startdate: DateTime.fromISO(building_period.startdate),
          // enddate: building_period.enddate ? DateTime.fromISO(building_period.enddate) : tomorrow,
          filter_tag: 'building_id',
          interval,
          type: 'building',
          campus_id,
          campus_name,
          settings,
          zone_layers
        }
      }).filter(Boolean)

      if (periods.length > 0) {
        this.building_periods_by_id[id] = periods
      }
    })


    // store each building as an array of periods
    this.building_periods = Object.values(this.building_periods_by_id)

    // store each floor as an array of periods
    this.floor_periods = Object.values(this.floor_periods_by_id)

    // link everything together
    this.building_periods.forEach(periods => {
      periods.forEach(building => {
        building.floors.forEach(floor => {
          floor.building = building
          floor.zones.forEach(zone => {
            zone.floor = floor
            zone.building = building
          })
        })
      })
    })

    // ensure that each building contains at least 1 period
    console.assert(this.building_periods.every(period => Array.isArray(period) && period.length >= 1))
    // ensure that each zone contains at least 1 period
    console.assert(this.zone_periods.every(period => Array.isArray(period) && period.length >= 1))

    console.timeEnd('create Infrastructure')
    // this fails
    // the reason is b/c zones can have multiple periods while floors and buildings can have a single period
    // in that case, only the last zone period is matched
    // the only way to fix it is by retrieving buildings, floors and zones separately based on start & end date
    // console.assert(this.zone_periods.every(periods => periods.every(period => period.building_id === period.building?.id)))
    // console.log(this.zone_periods.filter(periods => periods.filter(period => period.building_id === period.building?.id)))

    // console.debug('Stored buildings & periods', this.building_periods, this.zone_periods)
  }

  getCampuses (startdate, enddate) {
    let all_buildings = this.getBuildings(null, startdate, enddate)
    let campus_ids = new Set(all_buildings.map(b => b.campus_id))
    let campuses = []
    campus_ids.forEach(id => {
      let buildings = all_buildings.filter(b => b.campus_id === id)
      campuses.push({
        id,
        name: formatCampusName(id),
        type: 'campus',
        zone_layers: Array.from(new Set(
          buildings.flatMap(b => b.zone_layers.map(({ name }) => name))
        ), name => ({ name })),
        buildings
      })
    })
    return campuses
  }

  getMergedBuildingPeriods () {
    // returns an object map [building_id], with the merged building data, with the startdate set to the first periods start date
    return Object.keys(this.building_periods_by_id).reduce((buildings, id) => {
      let periods = this.building_periods_by_id[id]
      let lastPeriod = periods[periods.length - 1]
      let startdate = periods[0].startdate
      let enddate = lastPeriod.enddate

      return buildings.concat({
        ...lastPeriod,
        startdate,
        interval: makeInterval({ startdate, enddate })
      })
    }, [])
  }

  getBuildings (_, startdate, enddate) {
    if (_) {
      throw new Error('update to store.buildings()')
    }

    if (startdate) {
      return latestOverlappingPeriod({ periods: this.building_periods, startdate, enddate })
    } else {
      return currentPeriods(this.building_periods)
    }
  }

  getZones (_, startdate, enddate) {
    if (_) {
      throw new Error('update to store.zones()')
    }
    if (startdate) {
      return latestOverlappingPeriod({ periods: this.zone_periods, startdate, enddate })
    } else {
      return currentPeriods(this.zone_periods)
    }
  }

  getSubtractedZones (id, startdate, enddate) {
    /** this method expects a zone ID and will return zones that can be subtracted from it **/
    let space = this.findId(id, startdate, enddate)
    if (!space || space.type !== 'zone') {
      return null
    }

    let { normalised_points } = space
    let contained = this.getZones(null, startdate, enddate).filter(zone =>
      // exclude the zone itself
      zone.id !== id
      // get zones on the same floor (a zone is always on a single floor)
      && zone.floor_id === space.floor_id
      // exclude zones from the same zone_layer
      && zone.layer_name !== space.layer_name
      // get zones that are contained
      && zone.normalised_points.filter(points => polygonContains(normalised_points, points)).length >= zone.normalised_points.length / 2
    )

    /**
     * We need to check if there is an actual difference produced here.
     * I'm not sure why, but in some cases (e.g UHG > CA120 - Cypres, CA > Floor 1)
     * certain zones appear to contain zones but don't actually produce differences
     * I think it's because containedZones is computed by looking at the zone centroid,
     * which may be outside of the zone and inside a different zone.
     **/
    let diff = contained.length > 0 && difference([ normalised_points ], ...contained.map(zone => [zone.normalised_points]))
    if (diff?.length > 0) {
      return contained
    }

    return null
  }

  hasId (id) {
    return (
      id in this.zone_periods_by_id
      || id in this.floor_periods_by_id
      || id in this.building_periods_by_id
    )
  }

  findId (id, startdate, enddate) {
    if (!id) {
      // console.error('No ID passed', id, startdate, enddate)
      return null
    }

    if (typeof id === 'string' && id.startsWith('campus')) {
      let campuses = this.getCampuses(startdate, enddate)
      return campuses.find(campus => campus.id === id)
    }

    const periods = this.getPeriods(id)
    if (!periods) {
      // console.log(`No periods found for ${id}. This is probably due to passing an invalid ID (from another client?).`, {
      //   periods,
      //   building_periods_by_id: this.building_periods_by_id,
      //   floor_periods_by_id: this.floor_periods_by_id,
      //   zone_periods_by_id: this.zone_periods_by_id
      // })
      return null
    }

    if (startdate) {
      const interval = makeInterval({ startdate, enddate })
      const matches = periods.filter(period => periodOverlapsInterval(period, interval))
      if (matches.length === 0) {
        // console.log('No matching periods found for ', id, interval.toString(), periods)
      }

      return matches[matches.length - 1]
    } else {
      return periods[periods.length - 1]
    }
  }

  findIdAll(id, startdate, enddate) {
    if (!id) {
      return null
    }

    const periods = this.getPeriods(id)
    if (!periods) {
      return null
    }

    if (startdate) {
      const interval = makeInterval({ startdate, enddate })
      const matches = periods.filter(period => periodOverlapsInterval(period, interval))
      if (matches.length === 0) {
        console.log('No matching periods found for ', id, interval.toString(), periods)
      }

      return matches
    } else {
      return periods
    }
  }

  getMinMaxDate (ids) {
    let spaces = this.building_periods

    if (Array.isArray(ids) && ids.length > 0) {
      spaces = ids.map(id => this.getPeriods(id)).filter(Boolean)

      // this happens when we switch to different client (in development)
      if (spaces.length === 0) {
        spaces = this.building_periods
      }
    }

    const dates = spaces
      .flatMap(periods =>
        periods.flatMap(period => ([
          DateTime.fromISO(period.startdate),
          period.enddate ? DateTime.fromISO(period.enddate) : tomorrow
        ])
      ))
      .sort()

    return [ DateTime.min(...dates), DateTime.max(...dates) ]
  }

  getPeriods (id, startdate, enddate) {
    const periods = this.zone_periods_by_id[id] || this.floor_periods_by_id[id] || this.building_periods_by_id[id]
    if (!startdate && !enddate) {
      return periods
    }

    const interval = makeInterval({ startdate, enddate })
    return periods.filter(period => periodOverlapsInterval(period, interval))
  }

  hasValidZonePeriods(zones, startdate, enddate) {
    if (!zones.length) {
      return null
    }

    let hasValidPeriods

    if (startdate) {
      let interval = makeInterval({ startdate, enddate })
      let periods = zones.map((zone) =>
        this.findIdAll(zone.id).filter((period) =>
          period.interval.overlaps(interval)
        )
      )

      if (!periods) {
        return null
      }

      // a zone has valid periods if there is only one period
      // and it completely overlaps with the selected timeframe
      hasValidPeriods = periods.every((period) => {
        return (
          period.length === 1 &&
          period[0].startdate <= startdate.toISODate() &&
          (period[0].enddate || DateTime.now().toISODate()) >=
            enddate.toISODate()
        )
      })
    }

    return hasValidPeriods
  }

  updateBuildingSettings (building_settings) {
    let updatedBuildings = Object.entries(this.building_periods_by_id).reduce((acc, [id, buildings]) => {
      let updatedSettings = buildings.map(building => {
        let settings = building_settings.find(s => s.building === id)

        if (settings) {
          if (Array.isArray(settings.excluded_periods) && settings.excluded_periods.length > 0) {
            settings.excluded_periods.forEach(period => {
              period.startdatetime = DateTime.fromISO(period.startdate)
              period.enddatetime = period.enddate === null ? tomorrow : DateTime.fromISO(period.enddate)
              period.interval = makeInterval(period)
            })
          }

          return {
            ...building,
            settings
          }
        }

        return building
      })

      return {
        ...acc,
        [id]: updatedSettings
      }
    }, {})

    this.building_periods_by_id = updatedBuildings
  }
}

// use this function instead of Interval.overlaps (which is exclusive)
// Fixes the case where bookings on the same date as the zone start date would result in zone not found error.
function periodOverlapsInterval (period, interval) {
  return period.interval.end >= interval.start && period.interval.start <= interval.end
}

function currentPeriods (periods) {
  return periods
    .filter(periods => periods.some(period => period.enddate === null))
    .map(periods => periods[periods.length - 1])
}

function latestOverlappingPeriod ({ periods, startdate, enddate, interval }) {
  if (!interval) interval = makeInterval({ startdate, enddate })

  let result = periods
    .map(periods => periods.filter(period => period.interval.end >= interval.start && period.interval.start <= interval.end))
    .map(periods => periods[periods.length - 1])
    .filter(Boolean)

  // console.log({ periods, startdate, enddate, interval, result })

  return result
}

function makeInterval ({ startdate, enddate }) {
  if (!startdate) {
    throw new Error('makeInterval expects at least startdate')
  }

  if (!enddate) {
    if (enddate === null) {
      enddate = tomorrow
    } else {
      throw new Error('makeInterval expects explicit enddate when startdate is passed')
    }
  }

  if (DateTime.isDateTime(startdate)) {
    startdate = startdate.toISODate()
  }

  if (DateTime.isDateTime(enddate)) {
    enddate = enddate.toISODate()
  }

  return Interval.fromISO(`${startdate}/${enddate}`)
}
