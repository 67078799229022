import { DateTime } from 'luxon'
import { capitalizeFirstLetter } from './capitalize-first-letter'
import { getZoneTagKey, getZoneTagValue, isGroupTag, splitZoneTag } from './tag-utils'
import { LOCALE } from '../constants'

export function factor (value, max) {
  if (!Number.isFinite(value)) {
    return 'N/A'
  }

  return Number.isFinite(max) ? (value / max) : value
}

export function round (value) {
  if (!Number.isFinite(value)) {
    return 'N/A'
  }

  return Math.round(value)
}

export function roundBy (value, nr = 1, fn = 'round') {
  return Math[fn](value / nr) * nr
}

export function formatApiCapacity (capacity) {
  if (['default', 'domain'].includes(capacity)) {
    return `#${capacity}#`
  }

  return capacity
}

export function formatHtmlId(str) {
  return `id-${str.replace(/\W/g, '')}`
}

export function formatString (str) {
  return str ? str.split('_').map(capitalizeFirstLetter).join(' ') : ''
}

export function formatCampusName (campus_id) {
  return campus_id.replace('campus:', '')
}

export function formatCapacity (str) {
  if (str === 'default') {
    return 'Default capacity'
  }
  return formatString(str)
}

export function formatDataSource (datasource) {
  let layer = datasource?.name || datasource?.layer_name || datasource
  if (typeof layer === 'string' && layer.includes('layer')) {
    return formatString(datasource.replace(/layer/, ''))
  } else {
    throw new Error('invalid layer: ' + layer)
  }
}

export const formatTimeUTC = new Intl.DateTimeFormat(LOCALE, { ...DateTime.TIME_24_SIMPLE, timeZone: 'UTC' }).format
const formatDayUTC = new Intl.DateTimeFormat(LOCALE, { ...DateTime.DATE_SHORT, weekday: 'short', timeZone: 'UTC' }).format
const formatWeekUTC = new Intl.DateTimeFormat(LOCALE, { ...DateTime.DATE_SHORT, timeZone: 'UTC' }).format
const formatMonthUTC = new Intl.DateTimeFormat(LOCALE, { month: 'long', year: 'numeric', timeZone: 'UTC' }).format

export function createUTCDateFormat (interval) {
  switch (interval) {
    case 'PT5M':
    case 'PT15M':
    case 'PT1H':
      return formatTimeUTC
    case 'P1D':
      return formatDayUTC
    case 'P1W':
      return formatWeekUTC
    case 'P1M':
      return formatMonthUTC
    default:
      return () => null
  }
}

export function formatList (items) {
  let formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' })
  return formatter.format(items)
}

export function formatMetadata (metadata) {
  return formatString(metadata === 'has_bookingsource' ? 'bookable' : metadata)
}

const createNumberFormat = (config) => {
  const {format} = new Intl.NumberFormat(LOCALE, config)

  return (value, fallback = 'N/A') => Number.isFinite(value)
    ? format(value)
    : fallback
}

export const formatExact = createNumberFormat({
  maximumFractionDigits: 1
})

export const formatRounded = createNumberFormat({
  maximumFractionDigits: 0,
  signDisplay: 'negative'
})

export const formatPercent = createNumberFormat({
  style: 'percent',
  signDisplay: 'negative'
})

export const formatPercentDiff = createNumberFormat({
  style: 'percent',
  signDisplay: 'exceptZero'
})

export const formatRoundedDiff = createNumberFormat({
  maximumFractionDigits: 0,
  signDisplay: 'exceptZero'
})

export const formatMetric = (metric = 'utilisation') =>
  metric.startsWith('count')
    ? formatRounded
    : formatPercent

export const formatMetricDiff = (metric = 'utilisation') =>
  metric.startsWith('count')
    ? formatRoundedDiff
    : formatPercentDiff

export function formatUnitOfMeasure (value, output = 'metric', showSuffix = true) {
  let suffix = showSuffix ? ' m²' : ''
  if (output === 'imperial') {
    suffix = showSuffix ? ` ft²` : ''
    return `${formatRounded(value * 10.763910)}${suffix}`
  }

  return `${formatRounded(value)}${suffix}`
}

const pluralRules = new Intl.PluralRules(LOCALE)
export function formatPlural (count, singular, plural) {
  const grammaticalNumber = pluralRules.select(count);
  switch (grammaticalNumber) {
    case 'one':
      return count + ' ' + singular;
    case 'other':
      return count + ' ' + plural;
    default:
      throw new Error('Unknown: '+grammaticalNumber)
  }
}

export function formatTag (tag) {
  let [ key, value ] = splitZoneTag(tag)
  return `${formatString(key)}: ${formatString(value)}`
}

export function formatTagValue (tag) {
  if (isGroupTag(tag)) {
    let value = getZoneTagValue(tag)
    return formatString(value)
  } else {
    return formatString(tag)
  }
}

export function formatTagKey (tag) {
  if (isGroupTag(tag)) {
    let key = getZoneTagKey(tag)
    return formatString(key)
  } else {
    return formatString(tag)
  }
}

export function truncate (text, maxlength = Infinity) {
  if (typeof text === 'string' && text.length > maxlength) {
    return `${text.substring(0, maxlength)}…`
  } else {
    return text
  }
}

export function getFixedDecimalValue (value) {
  return parseFloat(value.toFixed(4))
}
