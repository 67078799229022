import { Box, Message } from '@lonerooftop/kitt-ui'

export function NotFoundPage () {
  return (
    <Box position='center'>
      <Message
        background='red-dark-2'
        title='Page not found'
        titleProps={{
          fontSize: 4
        }}
        description="The page you requested couldn't be found."
      />
    </Box>
  )
}