import features from "../utils/features";
import { uniq } from "../utils/uniq";
import { useActiveZonesByDataSource } from "./use-active-zones";
import { useSpaceLayer } from "./use-page-settings";

export function useActiveSpaceLayer(zones) {
  let activeZones = useActiveZonesByDataSource()
  let spaceLayer = useSpaceLayer()
  let availableSpaceLayers = getAvailableSpaceLayers(zones ?? activeZones)
  let activeSpaceLayer = spaceLayer

  if (!features.enabled('space-layer-selection')) {
    return null
  }

  // set default space layer according to priority
  if (!availableSpaceLayers?.includes(spaceLayer)) {
    activeSpaceLayer = defaultSpaceLayer(availableSpaceLayers)
  }

  return activeSpaceLayer
}

export function getAvailableSpaceLayers(zones) {
  let availableSpaceLayers = uniq(zones.map(z => z.metadata?.space_type)).filter(Boolean)

  return availableSpaceLayers
}

export function defaultSpaceLayer(availableSpaceLayers) {
  return SPACE_LAYERS_BY_PRIORITY.find(layer => availableSpaceLayers.includes(layer))
}

const SPACE_LAYERS_BY_PRIORITY = ['SPACE_POLYGON', 'SPACE_WIFI_ZONE', 'SPACE_NEIGHBORHOOD']
