import { useClientConfig } from './use-client-config'
import { useLocation } from 'react-router-dom'

import { getMenuAccess, findMenuItemByPath } from '../components/app/menu'

export function usePageAccess () {
  let config = useClientConfig()
  let location = useLocation()
  let menuItem = findMenuItemByPath(location.pathname)
  if (menuItem?.props) {
    return getMenuAccess({
      ...menuItem?.props, config
    })
  } else {
    // console.info(`No menu item found for page ${location.pathname}. By default, it is enabled for everyone`)
    return { beta: true, disabled: false, locked: false }
  }
}
