import PropTypes from 'prop-types'
import { FaCog, FaTimes } from 'react-icons/fa'
import { Button, ButtonPills } from '../button'
import { CardHeader } from '../cards'
import { Label } from '../text'
import { FavoriteButton } from '../favorite'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeading,
  PopoverClose
} from '../popover'
import { Tooltip } from '../tooltip'

import { ControlDarkMode } from '../select/control-dark-mode'
import { SelectQueryCampus } from '../select/select-campus'
import { SelectQueryBuilding } from '../select/select-building'
import { SelectQueryFloor } from '../select/select-floor'
import { SelectQueryZone } from '../select/select-zone'
import { SelectQueryDataSource } from '../select/select-data-source'
import { SelectQueryMetadata } from '../select/select-metadata'
import { SelectQueryTag } from '../select/select-tag'

import { SelectQueryDateRangePicker } from '../select/select-date-range'
import { SelectQueryTimeFrameDropdown, SelectQueryTimeFrameToday } from '../select/select-time-frame'
import { SelectQueryTimeframePrev, SelectQueryTimeframeNext } from '../select/select-timeframe-prev-next'

import { usePageSettings } from '../../hooks/use-page-settings'

export function Header (props) {
  // the header accepts props but can fall back to page settings
  // this allows us to read header props from other components
  // see DEFAULT_SETTINGS in page for the initial values
  let { fixed } = usePageSettings()

  let {
    children,
    // time
    timeframe = fixed.timeframe,
    showDatePicker = fixed.showDatePicker,
    showPrevNextTimeframe = fixed.showPrevNextTimeframe,
    // space
    selectCampus = fixed.selectCampus,
    selectBuilding = fixed.selectBuilding,
    selectFloor = fixed.selectFloor,
    selectZone = fixed.selectZone,
    selectGroup = fixed.selectGroup,
    showDarkMode = fixed.showDarkMode,
    showDataSource = fixed.showDataSource,
    // additional settings
    showFavorite = fixed.showFavorite,
  } = props

  let config = {
    space: !!selectCampus,
    actions: (showDarkMode || showDataSource || showFavorite),
    filter: !!selectGroup,
    time: (showDatePicker || timeframe),
    settings: !!children
  }

  let classes = Object.entries(config).map(([key, bool]) => `${bool ? 'has-' : 'no-'}${key}`)

  return (
    <header className={`app-header ${classes.join(' ')}`}>
      {config.space &&
        <div className='select-space'>
          <Label>
            Location
          </Label>
          <ButtonPills>
          {selectCampus && <SelectQueryCampus />}
          {selectBuilding && <SelectQueryBuilding multiple={isMultiple(selectBuilding)} />}
          {selectFloor && <SelectQueryFloor multiple={isMultiple(selectFloor)} />}
          {selectZone && <SelectQueryZone multiple={isMultiple(selectZone)} />}
          </ButtonPills>
        </div>}

      {config.actions &&
        <div className='actions'>
          {showDataSource && <SelectQueryDataSource />}
          {showFavorite && <FavoriteButton />}
          <ControlDarkMode />
        </div>}

      {config.filter &&
        <div className='select-filter' data-test-id='select-filter'>
          <Label>
            Filter
          </Label>
          <ButtonPills>
            <SelectQueryTag />
            <SelectQueryMetadata />
          </ButtonPills>
        </div>}

      {config.time &&
        <div className='select-time'>
          <Label>
            Time frame
          </Label>
          {timeframe === 'today' && <SelectQueryTimeFrameToday />}
          {timeframe !== 'today' && <ButtonPills>
            {showPrevNextTimeframe && <SelectQueryTimeframePrev />}
            {showDatePicker && <SelectQueryDateRangePicker />}
            {showPrevNextTimeframe && <SelectQueryTimeframeNext />}
          </ButtonPills>}
          {timeframe && timeframe !== 'today' && <SelectQueryTimeFrameDropdown timeframe={timeframe} />}
        </div>}

      {config.settings &&
        <div className='select-settings'>
          <HeaderSettings>{children}</HeaderSettings>
        </div>}
    </header>
  )
}

Header.propTypes = {
  // valid predefined options for time frame selection
  // if not defined, the time frame selection won't be shown
  timeframe: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),

  // whether to allow user to select custom start and end date; if no, they can still choose from timeframe options
  showDatePicker: PropTypes.bool,
  showPrevNextTimeframe: PropTypes.bool,

  // for each space type, determine whether it's selectable and what is selected
  selectCampus: PropTypes.bool,
  selectBuilding: PropTypes.oneOf([true, false, 'multiple']),
  selectFloor: PropTypes.oneOf([true, false, 'multiple']),
  selectZone: PropTypes.oneOf([true, false, 'multiple']),
  selectGroup: PropTypes.bool,
  showDataSource: PropTypes.bool,
}

export function HeaderSettings ({ children }) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button data-test-id='select-options' icon={<FaCog />}>
          Options
        </Button>
      </PopoverTrigger>
      <PopoverContent variant='primary'>
        <CardHeader display='flex' alignItems='center'>
          <PopoverHeading flex={1}>Configure this report</PopoverHeading>
          <PopoverClose variant='transparent' icon={<FaTimes />}></PopoverClose>
        </CardHeader>
        <div className='more-settings'>
          {children}
        </div>
      </PopoverContent>
    </Popover>
  )
}

export function MoreSettings ({ children }) {
  return children
}

MoreSettings.propTypes = {
  children: PropTypes.node
}

export function MoreSettingsItem ({ children }) {
  return children
}

MoreSettingsItem.propTypes = {
  children: PropTypes.node
}

export function MoreSettingsItemHeader ({ children }) {
  return (
    <legend className='more-settings-header'>
      {children}
    </legend>
  )
}

export function MoreSettingsItemLabel ({ children, feature, title }) {
  return (
    <Tooltip
      className='more-settings-item-label label'
      data-feature={feature}
      as={Label}
      title={title}
      placement='left'
    >
      {children ?? (feature ? 'beta' : null)}
    </Tooltip>
  )
}

export function MoreSettingsItemControl ({ children, title }) {
  return (
    <Tooltip
      className='more-settings-item-control'
      title={title}
      placement='left'
    >
      {children}
    </Tooltip>
  )
}

function isMultiple (prop) {
  return prop === 'multiple'
}
