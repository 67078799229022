import { putUserData } from '@lonerooftop/kitt-data'
import { deepEqual } from '@lonerooftop/kitt-data-react'
import { createContext, useEffect, useMemo, useReducer } from 'react'
import { captureUserClick } from '../utils/analytics'

export const UserDataContext = createContext()

export function UserDataContextProvider ({ children, userdata }) {
  // throughout the app, we only read & write to preferences via our local state
  let [ preferences, dispatch ] = useReducer(reducer, userdata)

  useEffect(() => {
    // whenever local preferences are changed, push the change to the server
    if (preferences !== userdata) {

      // grab changes by comparing all local key/value pairs with server result
      // TODO - we should refetch user data after saving for two reasons:
      // 1. the user might have made another change in a different tab which causes conflicting results (unlikely)
      // 2. the change we push to the server keeps increasing during the lifetime of the app
      let changes = Object.entries(preferences).reduce((acc, curr) => {
        let [ key, value ] = curr
        if (!deepEqual(userdata[key], value)) {
          acc[key] = value
        }

        return acc
      }, {})

      if (Object.keys(changes).length > 0) {
        console.log('update preferences', { preferences, changes })
        putUserData(changes)
          .then(response => {
            if (response?.result?.success !== 'ok') {
              console.error('unexpected response to putUserData', response?.result?.success)
            }
          })
          .catch(error => {
            console.error('something went wrong', error)
          })
      }
    }
  }, [ preferences, userdata ])

  let value = useMemo(() => {
    return {
      dispatch: (action) => {
        let actionType = action.type.toLowerCase().replace('_', ' ')
        dispatch(action)
        captureUserClick(`preference ${actionType}`, action.payload)
      },
      preferences
    }
  }, [ dispatch, preferences ])

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  )
}

function reducer (state, action) {
  switch (action.type) {
    case 'SET_DEFAULT_OPENING_HOURS': {
      return {
        ...state,
        opening_hours: action.payload
      }
    }

    case 'SET_UNIT_OF_MEASURE': {
      return {
        ...state,
        unit_of_measure: action.payload
      }
    }

    case 'SET_ACCURACY_DETECTION': {
      return {
        ...state,
        accuracy_detection: action.payload
      }
    }

    case 'SET_PREFERED_BUILDING': {
      return {
        ...state,
        prefered_building: action.payload
      }
    }

    case 'REMOVE_FAVORITE_URL': {
      return {
        ...state,
        favorite_urls: state.favorite_urls.filter(favorite => favorite?.to !== action.payload?.to)
      }
    }

    case 'ADD_FAVORITE_URL': {
      return {
        ...state,
        favorite_urls: [ ...state.favorite_urls, action.payload ]
      }
    }

    case 'EDIT_FAVORITE_URL': {
      return {
        ...state,
        favorite_urls: [ ...state.favorite_urls ].map(
          favorite => favorite?.to === action.payload?.to
            ? action.payload
            : favorite
        )
      }
    }

    case 'SET_CORE_HOURS_MIN_UTILIZATION': {
      return {
        ...state,
        core_hours_min_utilization: action.payload
      }
    }

    case 'SET_HVAC_MIN_UTILIZATION': {
      return {
        ...state,
        hvac_min_utilization: action.payload
      }
    }

    case 'SET_DATA_COLOR_PALETTE': {
      return {
        ...state,
        data_color_palette: action.payload
      }
    }

    default: {
      console.warn('unknown action', action)
      return state
    }
  }
}
