import { useOutletContext } from 'react-router-dom'
import { CardHeader, CardContent } from '../../../../components/cards'
import { DialogHeading, DialogContent, DialogClose, Dialog } from '../../../../components/dialog'
import { TimeMarkerFields } from './time-marker-fields'
import { Text } from '@lonerooftop/kitt-ui'
import { Message } from '../../../../components/message'
import { formatTimeMarkerFields, hasMatchingTimeMarker } from './time-marker-utils'
import { useTimeMarkers } from './use-time-markers'

export const CreateTimeMarker = () => {
  const {
    timeMarkerFields,
    updateTimeMarkerFields,
    setFromTo,
    closePopover,
    selectedSpaces,
    onChangeSpace,
    campusItems,
    buildingItems,
    floorItems,
    zoneItems,
  } = useTimeMarkers()
  const {
    allTimeMarkers,
    putTimeMarkersRequest,
    errorMessage,
    setErrorMessage,
    loading
  } = useOutletContext()

  const createTimeMarker = () => {
    if (hasMatchingTimeMarker(timeMarkerFields, allTimeMarkers)) {
      setErrorMessage(
        'A time marker with the same title, space and dates already exists.'
      )
    } else {
      setErrorMessage(null)
      putTimeMarkersRequest(formatTimeMarkerFields(timeMarkerFields))
    }
  }

  return (
    <Dialog open={true} onOpenChange={closePopover}>
      <DialogContent>
        <CardHeader display='flex' alignItems='center'>
          <DialogHeading flex={1}>Create new time marker</DialogHeading>
          <DialogClose />
        </CardHeader>
        <CardContent data-test-id="create-time-marker">
          {errorMessage && (
            <Message type='error' title={<Text fontSize={6} mb={0}>{errorMessage}</Text>} />
          )}
          <TimeMarkerFields
            mainCTA={createTimeMarker}
            mainCTALabel='Create'
            timeMarkerFields={timeMarkerFields}
            updateTimeMarkerFields={updateTimeMarkerFields}
            setFromTo={setFromTo}
            selectedSpaces={selectedSpaces}
            onChangeSpace={onChangeSpace}
            campusItems={campusItems}
            buildingItems={buildingItems}
            floorItems={floorItems}
            zoneItems={zoneItems}
            loading={loading}
          />
        </CardContent>
      </DialogContent>
    </Dialog>
  )
}

export default CreateTimeMarker
