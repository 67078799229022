import PropTypes from 'prop-types'
import { Children, memo } from 'react'
import { NavLink, useResolvedPath, useMatch, useLocation, useSearchParams } from 'react-router-dom'
import { Box, Icon } from '@lonerooftop/kitt-ui'
import { FaLock, FaClock, FaAngleDown, FaExternalLinkAlt } from 'react-icons/fa'
import { Button } from '../button'
import { TextTooltip } from '../tooltip'
import { SmallTitle } from '../text'

import { useClientConfig } from '../../hooks/use-client-config'
import { useStore, useSpaceHierarchy } from '../../hooks/use-store'
import { useLocalStorage } from '../../hooks/use-localstorage'
import { usePersistentScrollPosition } from '../../hooks/use-persistent-scroll-position'

import { DEFAULT_PERSISTENT_QUERY_PARAMS, QUERY_PARAMS } from '../../constants'

import { logout } from '@lonerooftop/kitt-data'
import { captureUserLogout, reset } from '../../utils/analytics'
import { createDatetime } from '../../utils/date-utils'
import features from '../../utils/features'

/**
 * Each menu link decides for itself what query parameters should persist
 * Requirements for the menu:
 * 1. Menu links should persist user-selected query keys. Each link has its own set of keys that should be persisted, which depends on the page it links to.
 * 2. Pages can require a minimum amount of data. If the selected space doesn't have that much historical data yet, the menu link should be disabled.
 * 3. Feature flags control who has access to certain pages. They can appear in the following forms, depending on the feature:
 *    a. "BETA" or locked.
 *      - NOTE - when locked, the route itself should show a "not authorized" page.
 *      - NOTE - this behaviour can be caused by feature flags
 *    b. shown or hidden (e.g. for certain client-specific features, like Clocks)
 *    c. shown or locked  (e.g. for certain paid-for features, like Booking )
 * 4. All pages except certain ones can be disabled. The config will determine what sections are available:
 *
 *      "bid_sections": [
 *        "booking_export_tool"
 *    ]
 *
 * 5. Menu's can be collapsable.
 *    a. By default, collapsable menus are collapsed
 *    b. If one of its items is selected on page load, it should be expanded by default.
 *    c. If one of its items is selected, it should be expanded.
 *    d. If a user expands or collapses a menu, this state should be preserved across page reloads.
 *
 **/
export function Menu () {
  return (
    <MenuWrapper>
      <MenuItem
        to='/'
        title='Home'
        persistQuery={[]}
      />
      <MenuSection title='Portfolio Insights'>
        <MenuItem
          to='/portfolio/utilization'
          title='Portfolio Utilization'
          persistQuery={[]}
          minimumDays={5*7}
        />
        <MenuItem
          to='/portfolio/comparison'
          title='Portfolio Comparison'
          featureFlag='portfolio-comparison'
          featureBeta
          featureHidden
        />
      </MenuSection>
      <MenuSection title='Building Insights'>
        <MenuItem
          to='/building/heatmap/now'
          title='Right Now'
        />
        <FoldableMenuItems title='Patterns' to='/building/patterns'>
          <MenuItem
            to='/building/patterns/pattern-of-use'
            title='Pattern Of Use'
          />
          <MenuItem
            to='/building/patterns/pattern-deviations'
            title='Pattern Deviations'
          />
          {features.enabled('pattern-of-use-v2') &&
            <MenuItem
              to='/building/patterns/pattern-expectations'
              title='Pattern Expectations'
            />}
        </FoldableMenuItems>

        <FoldableMenuItems title='Historical Analysis' to='/building/chart/days'>
          <MenuItem
            to='/building/chart/hours'
            title='Hourly Trend'
          />
          <MenuItem
            to='/building/chart/days'
            title='Daily Trend'
          />
          <MenuItem
            to='/building/chart/weeks'
            title='Weekly Trend'
          />
          <MenuItem
            to='/building/chart/months'
            title='Monthly Trend'
          />
          {features.enabled('pattern-of-use-v2')
            ? null
            : <MenuItem
                to='/building/chart/future'
                title='Expected Future Trend'
              />}
        </FoldableMenuItems>

        <FoldableMenuItems title='Compare' to='/building/compare/spaces'>
          <MenuItem
            to='/building/compare/spaces'
            title='Compare Spaces'
          />
        </FoldableMenuItems>

        <FoldableMenuItems title='Heatmap' to='/building/heatmap'>
          <MenuItem
            to='/building/heatmap/utilization'
            title='Utilization Rate'
            minimumDays={2*7}
          />
          <MenuItem
            to='/building/heatmap/count'
            title='Occupied Seats'
            minimumDays={7}
          />
          <MenuItem
            to='/building/heatmap/time-in-use'
            title='Time in use'
            minimumDays={7}
          />
        </FoldableMenuItems>

        <FoldableMenuItems title='Stacking Plan' to='/building/stacking'>
          <MenuItem
            to='/building/stacking/opportunity'
            title='Opportunity'
            minimumDays={4*7}
          />
          <MenuItem
            to='/building/stacking/utilization'
            title='Utilization'
            minimumDays={4*7}
          />
          <MenuItem
            to='/building/stacking/time-in-use'
            title='Time in use'
            minimumDays={4*7}
          />
        </FoldableMenuItems>

        <FoldableMenuItems title='Metrics Comparison' to='/building/utilization'>
          <MenuItem
            to='/building/utilization/group'
            title='Group metrics'
          />
          <MenuItem
            to='/building/utilization/inefficient'
            title='Inefficient use'
          />
          <MenuItem
            to='/building/utilization/underutilized'
            title='Under-utilization'
          />
          <MenuItem
            to='/building/utilization/bottleneck'
            title='Bottleneck'
          />
          <MenuItem
            to='/building/utilization/opportunity'
            title='High opportunity'
          />
        </FoldableMenuItems>
      </MenuSection>

      <MenuSection title='Room Insight'>
        <MenuItem
          to='/room/performance'
          title='Performance'
          minimumDays={6*7}
        />
        <MenuItem
          to='/room/demand'
          title='Room Demand'
          minimumDays={6*7}
        />
      </MenuSection>

      <MenuSection title='Bookings'>
        <FoldableMenuItems
          title='Booking Statistics'
          to='/booking/explore'
          featureFlag='booking-only-statistics'
          featureHidden
        >
          <MenuItem
            to='/booking/analyse'
            title='Analyse Booking Data'
            featureFlag='booking-only-statistics'
            featureHidden
          />
          <MenuItem
            to='/booking/attendance'
            title='Analyse Attendance'
            featureFlag='booking-only-statistics'
            featureHidden
          />
          <MenuItem
            to='/booking/popularity'
            title='Popular Times & Rooms'
            featureFlag='booking-only-statistics'
            featureHidden
          />
        </FoldableMenuItems>
        <FoldableMenuItems
          title='Booking Metrics'
          to='/booking/utilization'
          featureFlag='booking-analytics'
          featureHidden
        >
          <MenuItem
            to='/booking/utilization'
            title='Booking Utilization'
            featureFlag='booking-analytics'
            featureHidden
          />
          <MenuItem
            to='/booking/booked-rates-per-room'
            title='Booked Rates Per Room'
            featureFlag='booking-analytics'
            featureHidden
            minimumDays={4*7}
          />
          <MenuItem
            to='/booking/booked-rates-over-time'
            title='Booked Rates Over Time'
            featureFlag='booking-analytics'
            featureHidden
            minimumDays={4*7}
          />
        </FoldableMenuItems>
        <MenuItem
          to='/tools/corporate-noshows'
          title='Corporate No-Shows'
          featureFlag='timetabling-corporate-noshows'
          featureHidden
          minimumDays={7}
        />
      </MenuSection>

      <MenuSection title='Timetabling'>
        <MenuItem
          to='/tools/clocks'
          title='Clocks'
          featureFlag='timetabling-clocks'
          featureHidden
          minimumDays={7}
          bid_section='timetabling_clocks'
        />
        <MenuItem
          to='/tools/noshows'
          title='No-Shows'
          featureFlag='timetabling-noshows'
          featureHidden
          minimumDays={7}
          bid_section='no_shows'
        />
      </MenuSection>

      <MenuSection title='Actionable Tools'>
        <MenuItem
          to='/tools/find-team-space'
          title='Find team space'
          minimumDays={12*7}
        />

        <MenuItem
          to='/tools/consolidate'
          title='Consolidate Floors'
          featureFlag='consolidate-floors'
          featureBeta
          featureHidden
          minimumDays={6 * 7}
        />
      </MenuSection>

      <MenuSection title='Advanced Export'>
        <MenuItem
          to='/tools/export/zone-metrics'
          title='Zone Metrics'
          featureFlag='view-zone-metrics-export'
          featureBeta
          featureHidden
          persistQuery={[]}
        />
        <MenuItem
          to='/tools/export/booking-metrics'
          title='Booking Metrics'
          featureFlag='view-booking-metrics-export'
          featureBeta
          featureHidden
          bid_section='booking_export_tool'
          persistQuery={[]}
        />
      </MenuSection>

      <MenuSection title='Account'>
        <MenuItem
          to='/tools/timemarkers'
          title='Time Markers'
          featureFlag='timemarkers'
          featureBeta
          featureHidden
        />
        <MenuItem
          to='/tools/zones'
          title='Zones'
        />
        <MenuItem
          to='/tools/upload'
          title='Upload'
          featureFlag='upload-data'
          featureBeta
          featureHidden
        />
        <MenuItem
          to='/user/favorites'
          title='Favorites'
        />
        <MenuItem
          to='/user/settings'
          title='Settings'
          bid_section='settings'
        />
        <MenuItem
          to='/tools/uptime'
          title='Uptime'
        />
      </MenuSection>
      <MenuSection title='Support'>
        <MenuItem
          to='/videos'
          title='Video Tutorials'
          bid_section='videos'
        />
      {features.enabled('support-portal') &&
      <>
        <ExternalMenuLink href={'https://hubstarutilization.zendesk.com/'}>
          Support Portal
        </ExternalMenuLink>
        <ExternalMenuLink href={'https://hubstarutilization.zendesk.com/hc/en-us/requests/new'}>
          Submit a request
        </ExternalMenuLink>
      </>}
      </MenuSection>
      <Box m={3}>
        <Button display='block' onClick={async () => {
          try {
            // wait for all async posthog stuff to resolve
            await captureUserLogout()
            await reset()
            window.localStorage.clear()
            // logout does a reload immediately after
            logout()
          } catch (error) {
            logout()
          }
        }}>
          Logout
        </Button>
      </Box>
    </MenuWrapper>
  )
}

let MenuWrapper = memo(function MenuWrapper ({ children }) {
  // Don't lose the menu position when navigating between pages
  let { pathname } = useLocation()
  let ref = usePersistentScrollPosition({
    id: 'menu-scroll-position',
    key: pathname
  })

  return (
    <Box as='nav' className='app-menu' ref={ref}>
      {children}
    </Box>
  )
})

function MenuSection ({ title, children }) {
  let config = useClientConfig()
  let allChildrenHidden = Children.toArray(children).every(child => {
    let { hidden } = getMenuAccess({
      ...child.props,
      config
    })

    return hidden
  })

  if (allChildrenHidden) {
    console.debug(`MenuSection ${title} is hidden since all children are hidden and disabled`)
    return null
  }

  return (
    <div className='menu-section'>
      <MenuHeader>{title}</MenuHeader>
      <MenuItems>{children}</MenuItems>
    </div>
  )
}

function MenuHeader ({ children }) {
  return (
    <SmallTitle
      className='menu-header'
      p={3}
      mb={0}
      mt={2}
    >
      {children}
    </SmallTitle>
  )
}

function MenuItems ({ children }) {
  return (
    <div className='menu-items'>
      {children}
    </div>
  )
}

function FoldableMenuItems ({ children, title, to, featureFlag, featureHidden }) {
  let config = useClientConfig()
  let resolved = useResolvedPath(to)
  let match = useMatch({ path: resolved.pathname, end: false })
  let initialExpanded = Boolean(match)
  let [ expanded, setExpanded ] = useLocalStorage(`expand-${title}`, initialExpanded)
  let days = useMinimumDays()
  let minimumDays = Math.min(...Children.toArray(children).map(child => child.props.minimumDays ?? 0))
  let allChildrenDisabled = Children.toArray(children).every(child => {
    let { disabled } = getMenuAccess({
      ...child.props,
      config
    })

    return disabled
  })

  let enabled = !featureFlag || features.enabled(featureFlag)
  let enoughData = days >= minimumDays
  if (!enabled && featureHidden) {
    return null
  }

  // only enable folding if not all menu items are disabled
  let isFoldable = enoughData && !allChildrenDisabled
  if (!isFoldable) {
    expanded = false
  }

  return (
    <Box className='foldable-menu' my={1}>
      {/* As header, we re-use the first menu link and adjust its title */}
      <Box px={3} py={2} className={`menu-item${expanded ? ' menu-item-expanded' : ''}`}
        onClick={(e) => {
          e.preventDefault()
          // This will only store the expanded state. This ensures the menu item will be
          // automatically expanded when the user clicks on it, even when the user has manually closed it.
          setExpanded(!expanded || undefined)
        }}>
        {title}
        {isFoldable &&
          <Icon
            className={`foldable-icon rotatable${expanded ? ' rotate-180' : ''}`}
            lineHeight='extra-small'
          >
            <FaAngleDown />
          </Icon>}
      </Box>
      <div className='foldable-menu-items' aria-hidden={!expanded}>
        <div className='menu-items'>
          {children}
        </div>
      </div>
    </Box>
  )
}

FoldableMenuItems.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

function MenuItem (props) {
  let {
    children,
    minimumDays,
    persistQuery,
    to,
    title,
    ...rest
  } = props
  let config = useClientConfig()
  let days = useMinimumDays()
  // we only need explicit parameters, not page-specific ones
  let [ query ] = useSearchParams()

  let { beta, disabled, hidden, locked } = getMenuAccess({
    ...rest,
    config
  })

  let notEnoughData = days < minimumDays

  let classes = ['menu-item']

  if (hidden) {
    return null
  }

  if (locked) {
    to = '/forbidden'
    classes.push('cursor-locked')
  }

  if (disabled || notEnoughData) {
    classes.push('disabled')
  }

  let className = classes.filter(Boolean).join(' ')

  function onClick (e) {
    if (disabled || notEnoughData) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <Box
      as={NavLink}
      className={className}
      to={buildMenuLink(to, query, persistQuery)}
      px={3}
      py={2}
      onClick={onClick}
    >
      {title}

      {children ||
        <MenuItemIcon state={
          beta ? 'beta' :
          locked ? 'locked' :
          notEnoughData ? 'notEnoughData' :
          null} minimumDays={minimumDays} />}
    </Box>
  )
}

function ExternalMenuLink (props) {
  let { children, href } = props
  return (
    <Box
      as='a'
      className='menu-item'
      href={href}
      px={3}
      py={2}
      rel='noreferrer noopener'
      target='_blank'
    >
      {children}
     <TextTooltip
        className='menu-item-icon'
        fontWeight='normal'
        fontSize={8}
      >
      <Icon pl={2}>
        <FaExternalLinkAlt />
      </Icon>
      </TextTooltip>
    </Box>
  )
}

function useMinimumDays () {
  let store = useStore(2)
  let { ids } = useSpaceHierarchy()
  let [ minDate, maxDate ] = store.getMinMaxDate(ids)
  let duration = createDatetime(maxDate).diff(createDatetime(minDate)).as('days')
  return Math.round(duration)
}

function buildMenuLink (to, query, persistQueryParams = DEFAULT_PERSISTENT_QUERY_PARAMS) {
  let [path, menuParams ] = to.split('?')

  Array.from(query.keys()).forEach(key => {
    if (!persistQueryParams.includes(key)) {
      query.delete(key)
    }
  })

  if (menuParams) {
    menuParams.split('&').forEach(param => query.set(...param.split('=')))
  }

  let search = query.toString()
  return `${path.replace(/:.*/, '')}${search.length > 0 ? `?${search}` : ''}`
}

MenuItem.propTypes = {
  // this is the name with which this menu item can be disabled for clients
  // that have only limited BID functionality, such as SHU.
  // NOTE - we should keep this ID the same as the feature ID (if it exists)
  bid_section: PropTypes.string,
  children: PropTypes.node,

  // this will ensure the menu item won't be rendered
  hidden: PropTypes.bool,

  // if the feature is still in beta, it will show a tooltip next to the menu item
  featureBeta: PropTypes.bool,

  // the ID of the feature flag; if set, it will enable access control of this page via the feature flags
  featureFlag: PropTypes.string,

  // For users that don't have the feature, it won't show up in the menu.
  // If this is set, note that it should also have a featureFlag
  featureHidden: PropTypes.bool,

  // a list of query parameters that should be merged in with any link
  persistQuery: PropTypes.arrayOf(PropTypes.oneOf(Object.values(QUERY_PARAMS))),

  to: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired
}

MenuItem.defaultProps = {
  bid_section: undefined,
  hidden: false,
  featureBeta: false,
  featureFlag: undefined,
  featureHidden: false,
  persistQuery: DEFAULT_PERSISTENT_QUERY_PARAMS,
}

function MenuItemIcon (props) {
  let { state, minimumDays } = props
  if (!state) {
    return null
  }

  let TITLES = {
    beta: 'This feature is in beta and only available internally.',
    locked: 'This feature is disabled. Please mail us at utilization@hubstar.com to get access.',
    notEnoughData: `This report requires at least ${minimumDays} days of data. Please check back later.`
  }

  let ICONS = {
    beta: 'BETA',
    locked: <FaLock />,
    notEnoughData: <FaClock />
  }

  return (
    <TextTooltip
      className='menu-item-icon'
      fontWeight='normal'
      fontSize={8}
      placement='top'
      title={TITLES[state]}
    >
      <Icon pl={2}>
        {ICONS[state]}
      </Icon>
    </TextTooltip>
  )
}

export function getMenuAccess (props) {
  let {
    config,
    bid_section,
    hidden,
    featureBeta,
    featureFlag,
    featureHidden
  } = props

  let beta = featureBeta && Boolean(featureFlag)
  let enabled = featureFlag && features.enabled(featureFlag)
  let locked = Array.isArray(config?.bid_sections) && !config.bid_sections.includes(bid_section)

  if (!enabled && featureHidden) {
    hidden = true
  }

  let disabled = (beta && !enabled) || locked

  return { beta, disabled, enabled, hidden, locked }
}

export function findMenuItemByPath (pathname) {
  let items = Menu()
  let to = createTo(pathname)

  function isFound (child){
    return child?.props?.to === to
  }

  function searchChildren (children) {
    if (Array.isArray(children)) {
      for (let child of children) {
        if (isFound(child)) {
          return child
        }

        if (child?.props?.children) {
          let found = searchChildren(Children.toArray(child.props.children))
          if (found) {
            return found
          }
        }
      }
    }
  }

  return searchChildren(Children.toArray(items.props.children))
}

export function findSectionByPath (pathname) {
  let items = Menu()
  let to = createTo(pathname)

  function isFound (child) {
    return Children.toArray(child?.props?.children).some(child => child?.props?.to === to)
  }

  function searchChildren (children) {
    if (Array.isArray(children)) {
      for (let child of children) {
        if (isFound(child)) {
          return [child.props]
        }

        if (child?.props?.children) {
          let found = searchChildren(Children.toArray(child.props.children))
          if (found) {
            return [child.props, ...found]
          }
        }
      }
    } else {
      throw new Error('children should be an array')
    }
  }

  return searchChildren(Children.toArray(items.props.children))
}

function createTo (pathname) {
  if (pathname.length > 1 && pathname.endsWith('/')) {
    return pathname.replace(/\/$/, '')
  }

  return pathname
}
