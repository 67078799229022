import { DropdownMenu } from '../dropdown'

import { useCallback } from 'react'
import { useSetIds, useStartDate, useEndDate } from '../../hooks/use-page-settings'
import { useStore, useSpaces } from '../../hooks/use-store'

import { naturalSortBy } from '../../utils/sort'
import { getId } from '../../utils/space-utils'

export function SelectQueryFloor ({ multiple }) {
  let startdatetime = useStartDate()
  let enddatetime = useEndDate()
  let { buildings, floors } = useSpaces()
  let store = useStore(2)
  let onChange = useSetIds(multiple)

  let onClear = useCallback(
    () => {
      onChange(buildings.map(getId))
    },
    [onChange, buildings]
  )

  if (buildings.length !== 1) {
    return null
  }

  let selected = floors.map(getId)

  return buildings.map(({ id }) => {
    let items = store
      .floors(id, startdatetime, enddatetime)
      .map(floor => {
        let zones = store.zones(floor.id, startdatetime, enddatetime)
        return {
          key: floor.id,
          title: floor.name,
          disabled: zones.length === 0
        }
      })
      .sort(naturalSortBy('title'))

    return (
      <DropdownMenu
        key={id}
        items={items}
        multiple={multiple}
        onChange={onChange}
        onClear={onClear}
        selected={selected}
        title='Floor'
        data-test-id='select-floor'
      />
    )
  })
}

SelectQueryFloor.defaultProps = {
  multiple: false
}

SelectQueryFloor.propTypes = {
  multiple: DropdownMenu.propTypes.multiple
}
