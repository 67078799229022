import { Box } from '@lonerooftop/kitt-ui'
import { MessagePermissionDenied } from '../../components/message-permission-denied'

export function UnauthorizedPage () {
  return (
    <Box position='center'>
      <MessagePermissionDenied position='center' />
    </Box>
  )
}
