import { Loader } from '@lonerooftop/kitt-ui'
import { ErrorMessage } from './components/error-message'
import { StoreContext, StoreContextV2, ClientConfigContext } from './context'
import { UserDataContextProvider } from './context/user-data'
import { LoginPage } from './sections/auth/pages/login-page'
import { Sections } from './sections/index'

export function App (props) {
  console.log('render App', props)
  let {
    config,
    error,
    loading,
    session,
    store,
    store2,
    userdata
  } = props

  if (session) {
    if (session.session) {
      return (
        <StoreContextV2.Provider value={store2}>
          <StoreContext.Provider value={store}>
            <UserDataContextProvider userdata={userdata}>
              <ClientConfigContext.Provider value={config}>
                <Sections />
              </ClientConfigContext.Provider>
            </UserDataContextProvider>
          </StoreContext.Provider>
        </StoreContextV2.Provider>
      )
    }

    if (session.error) {
      return <LoginPage {...props.session} />
    }
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ErrorMessage error={error} position='center' />
  }
}
