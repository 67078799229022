// debug needs to be loaded first since it resets console
import { DEVELOPMENT } from './utils/debug'

import { DEFAULT_CONFIG, DEFAULT_USER_DATA } from './constants'

import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import * as kitt from '@lonerooftop/kitt-data'

import { App } from './app.js'
import { Analytics } from './components/analytics'
import { ErrorHandler, ReauthenticationMessage } from './components/error-handler'
import * as analytics from './utils/analytics'
import darkmode from './utils/dark-mode'
import { Infrastructure } from './utils/infrastructure'
import { Store } from './utils/store'
import features from './utils/features'

// All other styles should be imported from our main CSS entry
import './index.css'

const container = document.getElementById('root')
const root = createRoot(container)

function render (props = {}) {
  if (props.error && kitt.isLoginError(props.error)) {
    analytics.captureShowUserReauthentication()
    root.render(<ReauthenticationMessage />)
    return
  }

  root.render(
    <ErrorHandler>
      <BrowserRouter>
        <App {...props} />
        {props.loading ? null : <Analytics />}
      </BrowserRouter>
    </ErrorHandler>
  )
}

async function getFeatureFlags () {
  console.log('getFeatureFlags')
  let response = { result: { feature_flags: [] }}
  try {
    response = await kitt.privateGetFeatureFlags()
  } catch (error) {
    // this is a non-critical API call
    // we don't really care if it fails
    console.warn(error)
  }

  return response
}

async function boot (App) {
  console.group('boot')

  darkmode.init()

  kitt.init({
    app_id: 'jarvis',
    callback_url: '/auth/callback',
    // debug: true,
    storage: window.sessionStorage,
  })

  analytics.init()

  // initial render
  console.log('render loading')
  render({ loading: true })

  try {
    let session = await kitt.getSession()
    if (session.session) {
      console.log('2. authorized')
      // this call will trigger an initial load of feature flags
      analytics.captureUserLogin(session.session)

      // we need to fetch buildings first because getBuildingSettings depends on it
      let { result: { buildings }} = await kitt.getBuildings()

      // fetch these resources in parallel for a small speed boost
      let [
        { result: { building_settings }},
        { result: { zones }},
        { result: { files }},
        { result: userdata },
        { result: { feature_flags } }
      ] = await Promise.all([
        kitt.getBuildingSettings({
          building_id: buildings.map(building => building.id)
        }),
        kitt.getZones({filter_zones: `{"filter":["capacity.default",">",0]}`}),
        kitt.getStaticAssets(),
        kitt.getUserData(),
        getFeatureFlags()
      ])

      let store = new Infrastructure(buildings, zones, building_settings)
      let store2 = new Store(buildings, zones, building_settings)
      // window.old_store = store
      // window.store = store2
      console.log('3. created store')

      // get client-specific configuration
      let config = { ...DEFAULT_CONFIG }
      let asseturl = files?.find(f => f.name === 'jarvis/config.json')?.asseturl
      let wallyasseturl
      if (DEVELOPMENT) {
        let client = session.config['get-api-key-url'].split('.')[1]
        console.log('4. using local client config')
        asseturl = '/assets/config.json'
        wallyasseturl = `/assets/wally/${client}.json`
      }

      if (asseturl) {
        let configs = await fetch(asseturl).then(res => res.json())
        Object.assign(config, configs[0])
      }
      if (wallyasseturl) {
        try {
          let wallyconfig = await fetch(wallyasseturl).then(res => res.json())
          Object.assign(config, { wally: wallyconfig })
        } catch (e) {
          console.warn('No Wally config found for client')
        }
      }
      console.log('4. fetched client config')

      // merge in user-data defaults
      userdata = { ...DEFAULT_USER_DATA, ...userdata }
      console.log('5. fetched user data')

      let props = { config, loading: false, session, store, store2, userdata }

      // store feature flags in sessionStorage so we can use it as global
      features.init(
        feature_flags,
        userdata.feature_flags,
        () => render(props)
      )
      console.log('6. initialised feature flags')

      console.groupEnd('boot')

      render(props)
    } else {
      // track user login page views with explicit event
      analytics.captureShowUserLogin()

      console.log('2. not authorized, login first')
      console.groupEnd('boot')

      // not logged-in, render login screen
      render({
        loading: false,
        session
      })
    }

  } catch (error) {
    console.log('2. there was an error')
    console.groupEnd('boot')

    analytics.captureError(error)

    render({
      loading: false,
      error
    })
  }
}

boot()
