import { lazy, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { AppLoader } from '../components/app-loader'
import { NotFoundPage } from './not-found/not-found-page'

import { BookingRoutes } from './booking/booking-routes'
import { CallbackPage } from './auth/pages/callback-page'
import { ToolsRoutes } from './tools/tools-routes'
import { PortfolioRoutes } from './portfolio/portfolio-routes'
import { TestRoutes } from './test/test-routes'
import { UserRoutes } from './user/user-routes'
import { RoomRoutes } from './room/room-routes'
import { WallyRoutes } from './wally/index'

import features from '../utils/features'

const HomePage = lazy(() => import('./home/pages/home-page'))
const UnauthorizedPage = lazy(() => import('./auth/pages/unauthorized-page'))
const VideoTutorialsPage = lazy(() => import('./video-tutorials'))

/** Heatmap **/
const HeatmapPage = lazy(() => import('./heatmap/components/heatmap-page'))
const HeatmapPageContent = lazy(() => import('./heatmap/components/heatmap-page-content'))

/** Historical Analysis **/
const HistoricalHoursPage = lazy(() => import('./charts/historical-hours'))
const HistoricalDaysPage = lazy(() => import('./charts/historical-days'))
const HistoricalWeeksPage = lazy(() => import('./charts/historical-weeks'))
const HistoricalMonthsPage = lazy(() => import('./charts/historical-months'))

/** Compare **/
const CompareSpacesPage = lazy(() => import('./charts/compare/compare-spaces-page'))

/** Patterns **/
const PatternOfUsePage = lazy(() => {
  return features.enabled('pattern-of-use-v2')
    ? import('./charts/pattern-of-use/pattern-of-use-page')
    : import('./building/pages/pattern-of-use-page')
})

const PatternDeviationsPage = lazy(() => {
  return features.enabled('pattern-of-use-v2')
    ? import('./charts/pattern-deviations/pattern-deviations-page')
    : import('./building/pages/pattern-deviation-page')
})

const PatternExpectationsPage = lazy(() => {
  return features.enabled('pattern-of-use-v2')
    ? import('./charts/pattern-expectations/pattern-expectations-page')
    : import('./building/pages/charts-future-page')
})

/** Metrics Comparison **/
const MetricsComparisonBottleneckPage = lazy(() => import('./metrics-comparison/metrics-comparison-bottleneck'))
const MetricsComparisonGroupPage = lazy(() => import('./metrics-comparison/metrics-comparison-group'))
const MetricsComparisonInefficientPage = lazy(() => import('./metrics-comparison/metrics-comparison-inefficient-use'))
const MetricsComparisonOpportunityPage = lazy(() => import('./metrics-comparison/metrics-comparison-opportunity'))
const MetricsComparisonUnderutilizedPage = lazy(() => import('./metrics-comparison/metrics-comparison-underutilized'))

/** Stacking Plan **/
const StackingPage = lazy(() => import('./stacking/stacking-page'))
const StackingMetricPage = lazy(() => import('./stacking/stacking-metric-page'))

export function Sections () {
  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        {/* order doesn't really matter, but I'd like to keep it alphabetized */}
        <Route path='/' index element={<HomePage />} />
        <Route path='auth/callback' element={<CallbackPage />} />
        <Route path='booking/*' element={<BookingRoutes />} />
        <Route path='building/chart/days' element={<HistoricalDaysPage />} />
        <Route path='building/chart/future' element={features.enabled('pattern-of-use-v2')
          ? <Navigate to='/building/patterns/pattern-expectations' replace={true} />
          : <PatternExpectationsPage />
        } />
        <Route path='building/chart/hours' element={<HistoricalHoursPage />} />
        <Route path='building/chart/stacked' element={<Navigate to='/building/compare/spaces' replace={true} />} />
        <Route path='building/chart/months' element={<HistoricalMonthsPage />} />
        <Route path='building/chart/weeks' element={<HistoricalWeeksPage />} />
        {/* we accept any other path to chart and render the default report */}
        <Route path='building/chart/*' element={<HistoricalDaysPage />} />
        <Route path='building/compare/spaces' element={<CompareSpacesPage />} />
        <Route path='building/heatmap/*' element={<HeatmapPage />}>
          <Route path=':metric' element={<HeatmapPageContent />} />
        </Route>
        <Route path='building/patterns/pattern-of-use' element={<PatternOfUsePage />} />
        <Route path='building/patterns/pattern-deviations' element={<PatternDeviationsPage />} />
        <Route path='building/patterns/pattern-expectations' element={<PatternExpectationsPage />} />
        <Route path='building/stacking/*' element={<StackingPage />}>
          <Route path=':metric' element={<StackingMetricPage />} />
        </Route>
        <Route path='building/utilization/bottleneck' element={<MetricsComparisonBottleneckPage />} />
        <Route path='building/utilization/group' element={<MetricsComparisonGroupPage />} />
        <Route path='building/utilization/inefficient' element={<MetricsComparisonInefficientPage />} />
        <Route path='building/utilization/opportunity' element={<MetricsComparisonOpportunityPage />} />
        <Route path='building/utilization/underutilized' element={<MetricsComparisonUnderutilizedPage />} />
        <Route path='forbidden/*' element={<UnauthorizedPage />} />
        <Route path='portfolio/*' element={<PortfolioRoutes />} />
        <Route path='room/*' element={<RoomRoutes />} />
        <Route path='test/*' element={<TestRoutes />} />
        <Route path='tools/*' element={<ToolsRoutes />}/>
        <Route path='user/*' element={<UserRoutes />}/>
        <Route path='videos' element={<VideoTutorialsPage/>}/>
        <Route path='wally/*' element={<WallyRoutes />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}
