import { createInterval } from '../../../../utils/date-utils'

export const formatTimeMarkerFields = (timeMarkerFields = {}) => {
  const spaceIds = timeMarkerFields.spaceIds.map((spaceId) =>
    (typeof spaceId === 'string' && spaceId.startsWith('campus')) ? spaceId.replace('campus:', '') : spaceId
  )
  return {
    space_ids: spaceIds,
    title: timeMarkerFields.title,
    description: timeMarkerFields.description,
    startdatetime: timeMarkerFields.startDateTime,
    enddatetime: timeMarkerFields.endDateTime,
  }
}

export const formatTimeMarkersResponse = (timemarkers = [], store) => {
  return timemarkers?.map((timeMarker) => {
    return {
      id: timeMarker.id,
      spaceIds: timeMarker.space_ids
        .map(id => store.has(id) ? id : store.has(`campus:${id}`) ? `campus:${id}` : null)
        .filter(Boolean),
      title: timeMarker.title,
      description: timeMarker.description,
      startDateTime: timeMarker.startdatetime,
      endDateTime: timeMarker.enddatetime,
    }
  })
}

export const locationDrilldown = (space) => {
  // TODO: discuss how to handle displaying multiple spaces
  let result = space.name
  switch (space.type) {
    case 'zone':
      result = `${space.campus_name} > ${space.building_name} > ${space.floor_name} > ${result}`
      break
    case 'floor':
      result = `${space.campus_name} > ${space.building_name} > ${result}`
      break
    case 'building':
      result = `${space.campus_name} > ${result}`
      break
    default:
      break
  }
  return result
}

export const hasMatchingTimeMarker = (newMarker, allTimeMarkers, checkExactDates) => {
  return allTimeMarkers.some((marker => {
    // if in future we decide to support time (not just date), need to refactor matchingDates check
    const currMarkerStart = marker.startDateTime
    const currMarkerEnd = marker.endDateTime
    const newMarkerStart = newMarker.startDateTime
    const newMarkerEnd = newMarker.endDateTime
    let matchingDates = false
    if (checkExactDates) {
      const matchingStart = currMarkerStart === newMarkerStart
      const matchingEnd = currMarkerEnd === newMarkerEnd
      matchingDates = matchingStart && matchingEnd
    } else {
      const newMarkerInterval = createInterval(newMarkerStart, newMarkerEnd)
      matchingDates = createInterval(currMarkerStart, currMarkerEnd).overlaps(newMarkerInterval)
    }
    // TODO: handle arrays of multiple spaces in any order
    const matchingSpaces = JSON.stringify(marker.spaceIds) === JSON.stringify(newMarker.spaceIds)
    const matchingTitle = marker.title === newMarker.title
    const matchingDescription = marker.description === newMarker.description
    return matchingDates && matchingSpaces && matchingTitle && matchingDescription
  }))
}

export const generateSelectedSpaces = (selected, space) => {
  const result = { ...selected }
  switch (space.type) {
    // TODO: need to dedupe when handling multiple selected
    case 'zone':
      result.campus.push(space.campus_id)
      result.building.push(space.building_id)
      result.floor.push(space.floor_id)
      result.zone.push(space.id)
      break
    case 'floor':
      result.campus.push(space.campus_id)
      result.building.push(space.building_id)
      result.floor.push(space.id)
      break
    case 'building':
      result.campus.push(space.campus_id)
      result.building.push(space.id)
      break
    case 'campus':
      result.campus.push(space.id)
      break
    default:
      break
  }
  return result
}
