import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { Detail, Icon, Text } from '@lonerooftop/kitt-ui'
import { Button } from '../button'
import { Tooltip } from '../tooltip'

import { useCallback } from 'react'
import { useActiveOpeningHours } from '../../hooks/use-active-opening-hours'
import { useStore, useSpaces } from '../../hooks/use-store'
import { useStartDate, useEndDate, useSetDates } from '../../hooks/use-page-settings'

import { createDatetime, createDuration, getPreviousDates } from '../../utils/date-utils'
import { getId } from '../../utils/space-utils'

export function SelectQueryTimeframePrev () {
  let {
    prevStart,
    prevEnd,
    days,
    canGoBack,
    goBack
  } = useTimeframePrevNext()

  let prevTooltip = canGoBack
    ? <Text>Previous {days > 1 ? days : ''} day{days === 1 ? '' : 's'} <Detail display='block'>({prevStart.toLocaleString()}–{prevEnd.toLocaleString()})</Detail></Text>
    : 'Cannot select dates before utilization data is available'

  return (
    <Button
      className='button-icon time-frame-prev'
      disabled={!canGoBack}
      onClick={goBack}
    >
      <Tooltip as={Icon} placement='top' title={prevTooltip}>
        <FaChevronLeft />
      </Tooltip>
    </Button>
  )
}

export function SelectQueryTimeframeNext () {
  let {
    nextStart,
    nextEnd,
    days,
    canGoForward,
    goForward
  } = useTimeframePrevNext()

  let nextTooltip = canGoForward
    ? <Text>Next {days > 1 ? days : ''} day{days === 1 ? '' : 's'} <Detail display='block'>({nextStart.toLocaleString()}–{nextEnd.toLocaleString()})</Detail></Text>
    : 'Cannot select dates in the future'

  return (
    <Button
      className='button-icon time-frame-next'
      disabled={!canGoForward}
      onClick={goForward}
    >
      <Tooltip as={Icon} placement='top' title={nextTooltip}>
        <FaChevronRight />
      </Tooltip>
    </Button>
  )
}

function useTimeframePrevNext () {
  let start = createDatetime(useStartDate())
  let end = createDatetime(useEndDate())
  let setDates = useSetDates()
  let store = useStore(2)
  let { buildings } = useSpaces()
  let duration = createDuration(start, end)
  let [ minDate, maxDate ] = store.getMinMaxDate(buildings.map(getId))
  let { activeOpeningHours } = useActiveOpeningHours()
  let {
    previous_start: prevStart,
    previous_end: prevEnd,
    next_end: nextEnd,
    next_start: nextStart
  } = getPreviousDates(start, end, activeOpeningHours)

  let goBack = useCallback(() => {
    setDates(
      prevStart.toISODate(),
      prevEnd.toISODate()
    )
  }, [ setDates, prevStart, prevEnd ])

  let goForward = useCallback(() => {
    setDates(
      nextStart.toISODate(),
      nextEnd.toISODate()
    )
  }, [ setDates, nextStart, nextEnd ])

  return {
    prevStart,
    prevEnd,
    nextStart,
    nextEnd,
    days: Math.round(duration.as('days')),
    canGoBack: new Date(minDate) < prevStart,
    canGoForward: new Date(maxDate) > nextEnd,
    goBack,
    goForward
  }
}
