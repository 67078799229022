import { DropdownMenu } from '../dropdown'

import { useCallback } from 'react'
import {
  useStartDate,
  useEndDate,
  useSetIds
} from '../../hooks/use-page-settings'
import { useStore, useSpaces } from '../../hooks/use-store'
import { useActiveFilters } from '../../hooks/use-active-filters'

import { naturalSortBy } from '../../utils/sort'
import { getId } from '../../utils/space-utils'

export function SelectQueryZone ({ multiple }) {
  let store = useStore(2)
  let { filters } = useActiveFilters()
  let startdatetime = useStartDate()
  let enddatetime = useEndDate()
  let { buildings, floors, zones } = useSpaces()
  let onChange = useSetIds(multiple)
  let parents = floors.length > 0 ? floors : buildings

  let onClear = useCallback(
    () => onChange(parents.map(getId)),
    [ onChange, parents ]
  )

  if (buildings.length > 1) {
    // don't show zones dropdown with multiple buildings to prevent performance bottleneck
    return null
  }

  let items = parents.map(getId)
    .flatMap(id => store.zones(id, startdatetime, enddatetime))
    .filter(zone => filters.every(zone))
    .map(zone => ({
      key: zone.id,
      title: zone.name,
    }))
    .sort(naturalSortBy('title'))

  let selected = zones.map(getId)

  return (
    <DropdownMenu
      items={items}
      multiple={multiple}
      onChange={onChange}
      onClear={onClear}
      selected={selected}
      title='Zone'
      data-test-id='select-zone'
    />
  )
}

SelectQueryZone.defaultProps = {
  multiple: false
}

SelectQueryZone.propTypes = {
  multiple: DropdownMenu.propTypes.multiple
}
